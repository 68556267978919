.collection_skeleton .text_section {
    width: 240px;
}

.collection_skeleton .items_count {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.collection_skeleton .collection_section2 .skeleton_loader {
    transform: unset;
}

.collection_skeleton .total_tokens_traded {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.collection_skeleton .collection_profile {
    justify-content: flex-start;
    margin-bottom: 30px;
}

.collection_skeleton .collection_profile .image_section .skeleton_loader {
    border-radius: 5px;
}

.collection_skeleton .collection_profile .text_section {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 100%;
}

.collection_skeleton .collection_profile .text_section .text_section1 {
    height: 50px;
}

.collection_skeleton .collection_profile .text_section .text_section2 {
    margin-top: 0;
    width: 40%;
}

.collection_skeleton .collection_profile .text_section .text_section3 {

}

.collection_skeleton .collection_profile .text_section .text_section4 {
    height: 80px;
}

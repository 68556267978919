.explore_page {
    padding: 40px;
}

.explore_page > h2 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 140%;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 50px;
}

.explore_page > div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.explore_page > div button:hover {
    background: rgb(30 32 37);
}

.explore_page > div button:hover:before {
    content: '';
    position: absolute;
    background: rgb(30 32 37);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 30px;
}

.explore_page > div button:hover:after {
    content: '';
    background: linear-gradient(91.79deg, #FAC01E 3.15%, #FC762A 85.66%);
    position: absolute;
    top: -2px;
    left: -2px;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    z-index: -2;
    border-radius: 30px;
}

.explore_page > div button {
    color: #000000;
    background: rgba(33, 38, 43, 0.4);
    border: 1px solid #22272B;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 20px;
    margin-right: 5%;
}

.explore_page > div button:last-child {
    margin-right: 0;
}

.explore_page > div button p {
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.005em;
    background: linear-gradient(91.79deg, #fac01e 3.15%, #fc762a 85.66%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    text-transform: initial;
    width: 150px;
}

.explore_page > div button svg {
    width: 40px;
    height: 40px;
    margin-bottom: 20px;
}

@media (min-width: 1536px) {
    .explore_page {
        max-width: 1500px;
        width: 1500px;
        padding: 40px 0;
        margin: auto;
    }
}

@media (max-width: 1024px) {
    .explore_page > div button {
        margin: 20px;
    }
}

@media (max-width: 768px) {
    .explore_page {
        padding: 20px;
    }

    .explore_page > div button {
        margin: 10px;
    }
}

@media (max-width: 425px) {
    .explore_page > h2 {
        font-size: 30px;
    }
}

.mint_manage_page {
    background: rgba(33, 38, 43, 0.4);
    border: 1px solid #22272B;
    border-radius: 20px;
    margin: 5% 40px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mint_manage_page > h2 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 140%;
    text-align: center;
    color: #FFFFFF;
    width: 560px;
    margin: 0;
}

.mint_manage_page .get_in_touch {
    margin: 40px 0;
}

.mint_manage_page .get_in_touch button {
    background: linear-gradient(91.79deg, #fac01e 3.15%, #fc762a 85.66%);
    border-radius: 100px;
    text-transform: initial;
    padding: 10px 20px;
}

.mint_manage_page .get_in_touch button p {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    text-align: center;
    background: linear-gradient(180deg, #000000 0%, #11191F 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin-right: 10px;
}

.mint_manage_page .get_in_touch button svg {
    width: 16px;
}

.mint_manage_page .social_icons button {
    margin-right: 10px;
}

.mint_manage_page .social_icons button:last-child {
    margin-right: 0;
}

@media (min-width: 1536px) {
    .home_page .mint_manage_page {
        max-width: 1500px;
        width: 1500px;
        margin: 20px auto;
        padding: 40px 0;
    }
}

@media (max-width: 768px) {
    .mint_manage_page {
        margin: 20px;
        padding: 20px;
    }

    .mint_manage_page > h2 {
        display: contents;
    }
}

@media (max-width: 425px) {
    .mint_manage_page > h2 {
        font-size: 30px;
    }
}

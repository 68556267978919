.itc_page .itc_nft_section .card {
    padding: 14px;
    animation: 0.3s fadeIn ease-in-out;
    cursor: pointer;
    position: relative;
    z-index: 0;

    background: rgba(33, 38, 43, 0.4);
    border: 1px solid #22272B;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.itc_page .itc_nft_section .card .image_section {
    border-radius: 10px;
    width: 100%;
}

.itc_page .itc_nft_section .card .image_section img,
.itc_page .itc_nft_section .card .image_section .inline_video_section {
    width: 100%;
    height: 100%;
    border-radius: unset;
}

.itc_page .itc_nft_section .card .section1 {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 10px;
}

.itc_page .itc_nft_section .card .section1 .left_section .actions {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 10px 0 20px 0;
}

.itc_page .itc_nft_section .card .section1 .left_section button {
    position: relative;
    z-index: 1;
    text-transform: inherit;
    margin-right: 10px;
    min-width: 40px;
}

.itc_page .itc_nft_section .card .section1 .left_section button img {
    width: 16px;
    margin: 0 4px;
}

.itc_page .itc_nft_section .card .section1 .left_section button:before {
    content: '';
    position: absolute;
    background: #1B1B1B;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 100px;
}

.itc_page .itc_nft_section .card .section1 .left_section button:after {
    content: '';
    background: linear-gradient(123.89deg, #FAC01E 8.56%, #FC762A 86.47%);
    position: absolute;
    top: -2px;
    left: -2px;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    z-index: -2;
    border-radius: 100px;
}

.itc_page .itc_nft_section .card .section1 .left_section button > span {
    font-family: "Source Sans Pro", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    display: flex;
    align-items: center;
    background: linear-gradient(123.89deg, #FAC01E 8.56%, #FC762A 86.47%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.itc_page .itc_nft_section .card .section1 .right_section button {
    background: #333333;
    border-radius: 100px;
    width: 24px;
    height: 24px;
    min-width: 24px;
    padding: 0;
    margin-top: 5px;
}

.itc_page .itc_nft_section .card .section1 .right_section button svg {
    fill: #828282;
}

.itc_page .itc_nft_section .card .section1 .title {
    font-family: "Source Sans Pro", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #FFFFFF;

    text-align: left;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    line-clamp: 1;
    -webkit-box-orient: vertical;
}

.itc_page .itc_nft_section .card .section1 .description {
    font-family: "Source Sans Pro", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    letter-spacing: 0.005em;
    color: #828282;
    margin: 10px 0;

    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
}

.itc_page .itc_nft_section .card .section2 > div {
    font-family: "Source Sans Pro", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #828282;
    margin-bottom: 6px;
}

.itc_page .itc_nft_section .card .action {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.itc_page .itc_nft_section .card .action button {
    background: linear-gradient(123.89deg, #FAC01E 8.56%, #FC762A 86.47%);
    border-radius: 10px;

    font-family: "Source Sans Pro", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    display: flex;
    align-items: center;
    color: #121010;

    text-transform: capitalize;
    padding: 8px 20px;
    width: 100%;
}

.itc_page .itc_nft_section .card .action button:disabled {
    color: #000;
    opacity: 0.4;
}

.itc_page .itc_nft_section .card .action .counter {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.itc_page .itc_nft_section .card .action .counter .starts_in {
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #6FCF97;
}

.itc_page .itc_nft_section .card .action .counter .ends_in {
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #EB5757;
}

.itc_page .itc_nft_section .card .action .completed {
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #828282;
    margin-top: 10px;
}

.itc_page .itc_nft_section .card .card_info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
}

.itc_page .itc_nft_section .card .card_info:nth-child(2) {
    margin-bottom: 20px;
}

.itc_page .itc_nft_section .card .card_info > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.itc_page .itc_nft_section .card .card_info > div > span {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: rgba(255, 255, 255, 0.5);
}

.itc_page .itc_nft_section .card .card_info > div > p {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    font-feature-settings: 'zero' on, 'ss02' on;
    color: #FFFFFF;
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.itc_page .itc_nft_section .card .card_info > div > p img {
    width: 16px;
    margin: 0 6px;
}

/* List View */

/* Table Header*/
/*.itc_list_section {*/
/*    padding: 20px 40px;*/
/*}*/

.itc_list_section > div {
    background: unset;
    box-shadow: unset;
}

.itc_list_section table thead tr {
    background: rgba(35, 40, 44, 0.4);
    border: 1px solid #22272B;
    backdrop-filter: blur(2px);
    border-radius: 10px;
}

.itc_list_section table thead tr th {
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #AAAAAA;

    background: rgba(35, 40, 44, 0.4);
    border: 1px solid #22272B;
    backdrop-filter: blur(2px);
    padding-top: 4px;
    padding-bottom: 4px;
    /*min-width: 180px;*/
    min-width: 120px;
    text-align: center;
}

.itc_list_section table thead tr th button div {
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #AAAAAA;
}

.itc_list_section table thead tr th button div svg {
    fill: #AAAAAA;
}

.itc_list_section table thead tr th button {
    margin-right: 0;
}

.itc_list_section table thead tr th:first-child {
    min-width: 250px;
    border-radius: 5px 0 0 5px;
}

.itc_list_section table thead tr th:last-child {
    border-radius: 0 5px 5px 0;
}

.itc_list_section table thead tr th:nth-last-child(2) {
    min-width: 250px;
}

.itc_list_section table thead tr th:first-child > span {
    justify-content: flex-start;
}

.itc_list_section table thead tr th > span {
    justify-content: center;
}

/* Table Body */
.itc_list_section table tbody tr:nth-child(even) {
    /*background: #202020;*/
    /*border-radius: 5px;*/
    /*backdrop-filter: blur(2px);*/
}

.itc_list_section table tbody tr:nth-child(even):hover {
    /*background: #202020;*/
    /*backdrop-filter: blur(2px);*/
}

.itc_list_section table tbody tr:nth-child(even) td:first-child {
    border-radius: 5px 0 0 5px;
}

.itc_list_section table tbody tr:nth-child(even) td:last-child {
    border-radius: 0 5px 5px 0;
}

.itc_list_section table tbody tr td:nth-last-child(2) .clm_value {
    margin-left: 0;
}

.itc_list_section table tbody tr td {
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    background: unset;
    border-bottom: unset;
    height: 70px;
    border-bottom: 1px solid #2F363D;
}

.itc_list_section table tbody tr td:first-child {
    text-align: left;
}

.itc_list_section table tbody tr td:first-child .clm_value {
    margin-left: 0;
}

.itc_list_section table tbody tr td .clm_value {
    margin-right: 30px;
}

.itc_list_section table tbody tr td .remaining_tokens {
    display: flex;
    align-items: center;
    justify-content: center;
}

.itc_list_section table tbody tr td .remaining_tokens img {
    width: 20px;
    margin-right: 8px;
}

.itc_list_section table tbody tr td .starts_in {
    font-family: 'Ubuntu', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #27AE60;
}

.itc_list_section table tbody tr td .ends_in {
    font-family: 'Ubuntu', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #EB5757;
}

.itc_list_section table tbody tr td .completed {
    font-family: 'Ubuntu', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #828282;
}

.itc_list_section table tbody tr td .claim_button,
.itc_list_section table tbody tr td .clm_value.claim_button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0;
    margin-right: 0;
}

.itc_list_section table tbody .claim_button button {
    background: linear-gradient(123.89deg, #FAC01E 8.56%, #FC762A 86.47%);
    border-radius: 50px;

    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
    width: 120px;
    padding: 10px;
}

.itc_list_section table tbody .claim_button button:disabled {
    color: #000;
    opacity: 0.4;
}

/* Table Pagination */
.itc_list_section table tfoot tr {
    background: rgba(35, 40, 44, 0.4);
    border: 1px solid #22272B;
    backdrop-filter: blur(2px);
    border-radius: 0 0 10px 10px;
}

.itc_list_section table tfoot tr td {
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #757575;
    border-bottom: unset;
}

.itc_list_section table tfoot tr td div,
.itc_list_section table tfoot tr td p {
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #757575;
}

.itc_list_section table tfoot tr td svg {
    fill: #757575;
}

/* grid_view */
.itc_page .itc_nft_section .grid_view {
    /*background: rgba(33, 38, 43, 0.4);*/
    border: 1px solid #22272B;
    backdrop-filter: blur(2px);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    height: 259px;
}

.itc_page .itc_nft_section .grid_view:active {
    background-color: #6eb9f7;
    background-size: 100%;
    transition: background 0s;
}

.itc_page .itc_nft_section .grid_view:hover:before {
    content: '';
    position: absolute;
    background: #121516;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 10px;
}

.itc_page .itc_nft_section .grid_view:hover:after {
    content: '';
    background: linear-gradient(91.79deg, #FAC01E 3.15%, #FC762A 85.66%);
    position: absolute;
    top: -2px;
    left: -2px;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    z-index: -2;
    border-radius: 10px;
}

.itc_page .itc_nft_section .grid_view .left_section {
    /*border-right: 1px solid #22272B;*/
    border-radius: 10px 10px 0 0;
    width: 40%;
    height: 100%;
}

.itc_page .itc_nft_section .grid_view .left_section .default_thumbnail {
    height: 100%;
}

.itc_page .itc_nft_section .grid_view .left_section img,
.itc_page .itc_nft_section .grid_view .left_section .inline_video_section {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px 0 0 10px;
}

.itc_page .itc_nft_section .grid_view .right_section {
    width: 60%;
    padding: 10px;
    border-left: 1px solid #22272B;
}

.itc_page .itc_nft_section .grid_view .right_section .section1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.itc_page .itc_nft_section .grid_view .right_section .section1 .id {
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    font-feature-settings: 'zero' on, 'salt' on;
    color: #909395;
}

.itc_page .itc_nft_section .grid_view .right_section .section1 .status {
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    border-radius: 20px;
}

.itc_page .itc_nft_section .grid_view .right_section .section1 .status .stopped,
.itc_page .itc_nft_section .grid_view .right_section .section1 .status .completed {
    color: #EB5757;
    border: 1px solid #EB5757;
    border-radius: 20px;
    padding: 2px 14px;
}

.itc_page .itc_nft_section .grid_view .right_section .section1 .status .active {
    border: 1px solid #27AE60;
    color: #27AE60;
    border-radius: 20px;
    padding: 2px 14px;
}

.itc_page .itc_nft_section .grid_view .right_section .section1 .status .upcoming {
    color: #2D9CDB;
    border: 1px solid #2D9CDB;
    border-radius: 20px;
    padding: 2px 14px;
}

.itc_page .itc_nft_section .grid_view .right_section .section2 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 10px 0;
}

.itc_page .itc_nft_section .grid_view .right_section .section2 > span {
    display: flex;
    align-items: center;
    width: 100%;
}

.itc_page .itc_nft_section .grid_view .right_section .section2 img {
    margin-left: 10px;
}

.itc_page .itc_nft_section .grid_view .right_section .section2 .title {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    font-feature-settings: 'zero' on, 'ss02' on;
    color: #FFFFFF;

    text-align: left;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}

.itc_page .itc_nft_section .grid_view .right_section .section2 .creator {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    font-feature-settings: 'salt' on, 'zero' on, 'ss04' on;
    color: #F3F3F3;
}

.itc_page .itc_nft_section .grid_view .right_section .section3 {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.itc_page .itc_nft_section .grid_view .right_section .section3 > div {
    border-bottom: 1px solid #22272B;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 6px 0;
}

.itc_page .itc_nft_section .grid_view .right_section .section3 > div:last-child {
    border-bottom: unset;
}

.itc_page .itc_nft_section .grid_view .right_section .section3 > div > span {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: rgba(255, 255, 255, 0.5);
    text-align: left;
}

.itc_page .itc_nft_section .grid_view .right_section .section3 > div > p {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    font-feature-settings: 'zero' on, 'ss02' on;
    color: #FFFFFF;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    width: 50%;
    text-align: right;
}

.itc_page .itc_nft_section .grid_view .right_section .section3 > div.interaction_type > p {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.itc_page .itc_nft_section .grid_view .right_section .section3 > div.interaction_type > p img {
    margin: 0 2px;
}

.itc_page .itc_nft_section .grid_view .right_section .section3 > div.claim_type > p {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.itc_page .itc_nft_section .grid_view .right_section .section3 > div.claim_type > p > span {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.itc_page .itc_nft_section .grid_view .right_section .section3 > div > p img {
    width: 16px;
    margin: 0 6px;
}

.itc_page .itc_nft_section .grid_view .right_section .section3 .input_collection {

}

.itc_page .itc_nft_section .grid_view .right_section .section3 .input_collection:hover > p {
    text-decoration: underline;
    cursor: pointer;
}

.itc_page .itc_nft_section .grid_view .right_section .section4 {

}

.itc_page .itc_nft_section .grid_view .right_section .section4 .action {

}

.itc_page .itc_nft_section .grid_view .right_section .section4 .action .completed {
    font-family: 'Inter', sans-serif;
    font-style: italic;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    text-align: right;
    font-feature-settings: 'zero' on, 'ss02' on;
    color: #809FB6;
    margin-top: 10px;
}

.itc_page .itc_nft_section .grid_view .right_section .section4 .action .counter {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.itc_page .itc_nft_section .grid_view .right_section .section4 .action .counter .starts_in {
    /*font-family: 'Source Sans Pro', sans-serif;*/
    /*font-style: normal;*/
    /*font-weight: 400;*/
    /*font-size: 16px;*/
    /*line-height: 20px;*/
    /*display: flex;*/
    /*align-items: center;*/
    /*color: #6FCF97;*/

    font-family: 'Inter', sans-serif;
    font-style: italic;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    text-align: right;
    font-feature-settings: 'zero' on, 'ss02' on;
    color: #809FB6;
}

.itc_page .itc_nft_section .grid_view .right_section .section4 .action .counter .ends_in {
    /*font-family: 'Source Sans Pro', sans-serif;*/
    /*font-style: normal;*/
    /*font-weight: 400;*/
    /*font-size: 16px;*/
    /*line-height: 20px;*/
    /*display: flex;*/
    /*align-items: center;*/
    /*color: #EB5757;*/

    font-family: 'Inter', sans-serif;
    font-style: italic;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    text-align: right;
    font-feature-settings: 'zero' on, 'ss02' on;
    color: #809FB6;
}

.itc_page .itc_nft_section .grid_view .right_section .section4 .action {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.itc_page .itc_nft_section .grid_view .right_section .section4 .action button {
    position: relative;
    z-index: 1;

    text-transform: capitalize;
    padding: 6px 10px;
    width: 100%;
}

.itc_page .itc_nft_section .grid_view .right_section .section4 .action button:disabled {
    color: #000;
    opacity: 0.4;
}

.itc_page .itc_nft_section .grid_view .right_section .section4 .action button:before {
    content: '';
    position: absolute;
    background: #1B1B1B;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 4px;
}

.itc_page .itc_nft_section .grid_view .right_section .section4 .action button:after {
    content: '';
    background: linear-gradient(123.89deg, #FAC01E 8.56%, #FC762A 86.47%);
    position: absolute;
    top: -2px;
    left: -2px;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    z-index: -2;
    border-radius: 4px;
}

.itc_page .itc_nft_section .grid_view .right_section .section4 .action button > span {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;
    font-feature-settings: 'zero' on, 'ss02' on;

    background: linear-gradient(123.89deg, #FAC01E 8.56%, #FC762A 86.47%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@media (min-width: 1600px) {
    .itc_list_section table tbody tr td .clm_value {
        margin-right: 0;
        margin-left: -30px;
    }

    .itc_list_section table tbody tr td:nth-child(2) div {
        margin-left: -30px;
    }

    .itc_list_section table thead tr th:nth-last-child(2) {
        min-width: 220px;
    }

    /*.itc_list_section table tbody tr td:nth-child(2) .clm_value,*/
    /*.itc_list_section table tbody tr td:nth-child(3) .clm_value,*/
    /*.itc_list_section table tbody tr td:nth-child(4) .clm_value,*/
    /*.itc_list_section table tbody tr td:nth-child(5) .clm_value {*/
    /*    width: 100px;*/
    /*}*/
}

@media (max-width: 1440px) {
    /*.itc_page .itc_nft_section .grid_view {*/
    /*    height: 280px;*/
    /*}*/
    .itc_page .itc_nft_section .grid_view .left_section {
        /*width: 280px;*/
        /*flex-shrink: 0;*/
        height: 100%;
    }

    .itc_page .itc_nft_section .grid_view .left_section img,
    .itc_page .itc_nft_section .grid_view .left_section .inline_video_section {
        object-fit: cover;
    }
}

@media (max-width: 700px) {
    /*.itc_list_section {*/
    /*    padding: 20px;*/
    /*}*/
}

@media (max-width: 520px) {
    .itc_page .itc_nft_section .grid_view {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: auto;
    }

    .itc_page .itc_nft_section .grid_view .left_section {
        border-radius: 10px 10px 0 0;
        margin-bottom: 10px;
        border-bottom: 1px solid #22272B;
    }

    .itc_page .itc_nft_section .grid_view .left_section img,
    .itc_page .itc_nft_section .grid_view .left_section .inline_video_section {
        border-radius: 10px 10px 0 0;
    }

    .itc_page .itc_nft_section .grid_view .right_section {
        width: 100%;
    }
}

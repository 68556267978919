.my_account {
    padding: 60px 0 0;
    border-top: 1px solid #22272B;
}

.my_account .owner_nfts {
    background: #131618;
}

.my_account h2 {
    margin: 0 0 30px;
    font-family: 'Work Sans', sans-serif;
    font-weight: 600;
    font-size: 40px;
    line-height: 47px;
    text-align: center;
}

.my_account .address {
    background: #1b1e20;
    border: 1px solid #2d3439;
    border-radius: 50px;
    width: max-content;
    padding: 15px 30px;
    margin: auto auto 60px;
    display: flex;
    align-items: center;
    font-size: 24px;
    line-height: 33px;
    letter-spacing: .005em;
    -webkit-font-feature-settings: "zero" on;
    font-feature-settings: "zero" on;
    color: #fff;
    position: relative;
}

.my_account .address .icon {
    margin-left: 30px;
    width: 24px;
}

.my_account .address .copy_text {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(27, 30, 32, 0.5);
    backdrop-filter: blur(20px);
    border-radius: 50px;
    cursor: pointer;
    display: none;
}

.my_account .address:hover .copy_text,
.my_account .address .copy_text.show {
    display: flex;
    align-items: center;
    justify-content: center;
}

.my_account .horizontal_tabs {
    flex-direction: row;
    justify-content: center;
    box-shadow: unset;
    /*transition: all 0.3s;*/
    position: sticky;
    top: 80px;
    z-index: 11;
    border-bottom: 1px solid #22272B;
}

.my_account.fix .horizontal_tabs {
    background: #131618;
}

.my_account .horizontal_tabs div[role='tablist'] {
    padding: 20px 30px;
}

.my_account .horizontal_tabs .tab {
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #CECECE;
    text-transform: unset;
    padding: 6px 20px;
    min-width: auto;
    min-height: auto;
}

.my_account .horizontal_tabs .tab.active_tab {
    background: #22272B;
    border-radius: 50px;
}

.my_account .horizontal_tabs > div > span:last-child {
    background: linear-gradient(
            91deg, #FAC01E 3.15%, #FC762A 85.66%);
    height: 3px;
    border-radius: 20px;
    display: none;
}

.my_account .filters,
.my_account .active_card {
    position: sticky;
    top: 143px;
    overflow-y: auto !important;
    height: calc(100vh - 143px);
}

.my_account .explore_header {
    position: sticky;
    top: 158px;
    /*background: #131618;*/
    z-index: 2;
    background: #191D20;
    border-width: 1px 0;
    border-style: solid;
    border-color: #22272B;
    padding: 20px;
    /*position: relative;*/
}

.my_account .horizontal_tabs.sticky div[role='tablist'] {
    justify-content: center;
    align-items: center;
}

.my_account .horizontal_tabs .address_div {
    display: none;
}

.horizontal_tabs.sticky .address_div {
    display: flex;
    /*flex: 1;*/
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    left: 30px;
}

.my_account .horizontal_tabs .address {
    margin: unset;
    font-size: 18px;
    padding: 6px 20px;
}

.my_account .explore_main .explore {
    height: unset;
}

.my_account .list_page.active {
    scroll-margin: 202px;
}

.my_account .collection_list .list_page a,
.collection_list .list_page a {
    text-decoration: none;
}

.radio_select {
    display: none;
}

.list_page.selectable .radio_select {
    position: absolute;
    width: 50px;
    height: 50px;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(123.89deg, #FAC01E 8.56%, #FC762A 86.47%);
    border-radius: 0 10px 0 34px;
    z-index: 9;
}

.list_page.selectable .radio_select > span {
    border: 2px solid #000000;
    width: 20px;
    height: 20px;
    border-radius: 50px;
}

/* Activity && Offer Table CSS */
.activity_table {
    padding: 40px;
}

.activity_table > div {
    background: transparent;
    box-shadow: unset;
}

.activity_table table {
    border-collapse: separate;
    border-spacing: 0 5px;
}

.activity_table table thead {
    background: rgba(70, 70, 70, 0.5);
}

.activity_table table thead tr {
    border-radius: 10px 10px 0 0;
}

.activity_table table thead tr th {
    background: #46464680;
    border-bottom: none;
}

.activity_table table thead tr th div {
    font-family: 'Work Sans', sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #FFFFFF;
    text-align: center;
}

.activity_table table thead tr th:first-child {
    border-radius: 10px 0 0 0;
}

.activity_table table thead tr th:last-child {
    border-radius: 0 10px 0 0;
}

.activity_table table thead tr th:nth-child(1) > span,
.activity_table table thead tr th:nth-child(2) > span {
    justify-content: flex-start;
}

.activity_table table tfoot tr td {
    border-bottom: none;
}

.activity_table table thead tr th > span {
    justify-content: center;
}

.activity_table table tbody tr {
    border: 1px solid #22272B;
    border-radius: 5px;
    box-sizing: border-box;
}

.activity_table table tbody tr td {
    border-bottom: none;
    background: #23282c66;
}

.activity_table table tbody tr td div {
    font-family: 'Work Sans', sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #F2F2F2;
}

.activity_table table tbody tr td div .no_data_table {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.activity_table table tbody tr td .item_value,
.activity_table table tbody tr td .token_amount,
.activity_table table tbody tr td .quantity,
.activity_table table tbody tr td .from_value,
.activity_table table tbody tr td .to_value,
.activity_table table tbody tr td .time {
    font-family: 'Work Sans', sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #B7B7B7;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.activity_table table tbody tr td div .from_value {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
    text-align: left;
}

.activity_table table tbody tr td div .token_amount,
.activity_table table thead tr th:nth-child(2) {
    width: 160px;
}

.activity_table table tbody tr td div .time {
    width: 120px;
}

.activity_table table tbody tr td .token_amount,
.activity_table table tbody tr td .quantity,
.activity_table table tbody tr td .from_value,
.activity_table table tbody tr td .to_value,
.activity_table table tbody tr td .time {
    display: flex;
    align-items: center;
    justify-content: center;
}

.activity_table table tbody tr td .from_value .hash_text,
.activity_table table tbody tr td .to_value .hash_text {
    max-width: 150px;
    font-family: 'Work Sans', sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #B7B7B7;
}

.activity_table table tbody tr td .matched_address {

}

.activity_table table tbody tr td .token_amount > span {
    margin-left: 10px;
}

.activity_table table tbody tr td .item_value img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
    border-radius: 5px;
}

.activity_table table tbody tr td .positive_value {
    font-family: 'Work Sans', sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #27AE60;
}

.activity_table table tbody tr td div.negative_value {
    font-family: 'Work Sans', sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #EB5757;
}

.activity_table table tbody tr td .link_div {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.activity_table table tbody tr td .link_div svg {
    width: 18px;
    height: 18px;
}

.activity_table table tbody tr td .action_buttons {
    display: flex;
    align-items: center;
    justify-content: center;
}

.activity_table table tbody tr td .cancel_button {
    font-family: 'Work Sans', sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.005em;
    text-transform: capitalize;
    padding: 10px 20px;
    position: relative;
    z-index: 0;
    color: unset;
}

.activity_table table tbody tr td .cancel_button > span {
    background: linear-gradient(91.79deg, #FAC01E 3.15%, #FC762A 85.66%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.activity_table table tbody tr td .cancel_button:before {
    content: '';
    position: absolute;
    background: #23282C;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 50px;
}

.activity_table table tbody tr td .cancel_button:after {
    content: '';
    background: linear-gradient(91.79deg, #FAC01E 3.15%, #FC762A 85.66%);
    position: absolute;
    top: -1px;
    left: -1px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    z-index: -2;
    border-radius: 50px;
}

.activity_table table tbody tr td .accept_button {
    font-family: 'Work Sans', sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.005em;
    background: linear-gradient(91.79deg, #FAC01E 3.15%, #FC762A 85.66%);
    border-radius: 50px;
    text-transform: capitalize;
    padding: 10px 20px;
    color: #000000;
}

.activity_table table tbody tr td .tx_hash.matched_address {
    background: linear-gradient(91.79deg, rgba(250, 192, 30, 0.7) 3.15%, rgba(252, 118, 42, 0.7) 85.66%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.activity_table div table tfoot tr td div > div > div > div > svg {
    fill: #b7b7b778;
}

.activity_table div table tfoot tr td div > div > div > div button:disabled svg {
    fill: #b7b7b778;
}

/*collection list*/
.collection_list .list_page {
    padding: 0;
    border: unset;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.collection_list .list_section2 {
    border-radius: 10px 10px 0 0;
}

.collection_list .list_page .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
}

.collection_list .row .list_section3:last-child {
    align-items: flex-end;
}

.collection_list .row .list_section3:last-child .list_section3_span {
    text-align: right;
}

.collection_list .row .list_section3_div {
    margin-top: unset;
    margin-bottom: 3px;
    width: auto;
}

.collection_list .row .list_section3_div:hover,
.collection_list .row .list_section3_div > span:hover,
.collection_list .list_section3 .list_section3_span {
    text-decoration: unset;
    cursor: default;
}

.collection_list .list_section3 .list_section3_span {
    width: auto;
}

.collection_list .list_section3:first-child {
    width: 60%;
}

.collection_list .list_section3:last-child {
    width: 40%;
}

.collection_list .list_section3:first-child .list_section3_span {
    text-align: left;
    width: 100%;
}

.lists.no_data_list {
    display: flex;
}

.owner_nfts .list_section4 > div .lsl_value:hover:after {
    content: "";
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    border-width: 0;
    border-style: solid;
}

/* Grid View List View */
.my_account .explore_header .explore_head_right .grid_list_view {
    margin-right: 10px;
}

.my_account .explore_header .explore_head_right .grid_list_view button {
    background: rgba(33, 38, 43, 0.4);
    border: 1px solid #2F363D;
    min-width: auto;
    padding: 8px 12px;
}

.my_account .explore_header .explore_head_right .grid_list_view button svg {
    fill: #CECECE;
}

.my_account .explore_header .explore_head_right .grid_list_view .active_view {
    background: linear-gradient(123.89deg, #FAC01E 8.56%, #FC762A 86.47%);
}

.my_account .explore_header .explore_head_right .grid_list_view .active_view svg {
    fill: black;
}

.my_account .explore_header .explore_head_right .grid_list_view button:first-child {
    border-radius: 50px 0 0 50px;
}

.my_account .explore_header .explore_head_right .grid_list_view button:last-child {
    border-radius: 0 50px 50px 0;
}

.my_account .explore_header .explore_head_right .bulk_select_button {
    background: rgba(33, 38, 43, 0.4);
    border: 1px solid #2F363D;
    border-radius: 50px;
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #CECECE;
    padding: 8px 12px;
    text-transform: capitalize;
    width: auto;
    height: auto;
    margin-right: 10px;
}

.explore_header .bulk_selected {
    position: absolute;
}

.explore_header .bulk_selected {
    background: #191D20;
    box-shadow: 0 -10px 10px rgba(0, 0, 0, 0.3);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 40px;
    height: 100%;
    z-index: 9;
}

.explore_header .bulk_selected .left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.explore_header .bulk_selected .left button {
}

.explore_header .bulk_selected .left .cancel_button svg {
    fill: #f2f2f2;
    margin-right: 6px;
}

.explore_header .bulk_selected .left .cancel_button {
    background: #EB5757;
    border-radius: 50px;
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #FFFFFF;
    text-transform: capitalize;
    padding: 8px 10px;
    margin-right: 6px;
}

.explore_header .bulk_selected .left .deselect_button {
    background: rgba(33, 38, 43, 0.4);
    border: 1px solid rgba(75, 75, 75, 0.5);
    border-radius: 50px;

    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #828282;
    text-transform: capitalize;
    padding: 0;
    height: 40px;
}

.explore_header .bulk_selected .left .deselect_button > div {
    padding: 10px 12px;
}

.explore_header .bulk_selected .left .deselect_button label {
    margin-right: 0;
}

.explore_header .bulk_selected .left .deselect_button label > span {
    padding: 0;
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #828282;
    text-transform: capitalize;
}

.explore_header .bulk_selected .left .deselect_button label > span:first-child {
    padding-left: 10px;
    padding-right: 6px;
}

.explore_header .bulk_selected .left .deselect_button label svg {
    fill: #828282;
    width: 20px;
}

.explore_header .bulk_selected .left > span {
    background: #828282;
    width: 1px;
    height: 20px;
    margin: 0 10px;
}

.explore_header .bulk_selected .left h2 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
    margin: 0;
}

.explore_header .bulk_selected .right button {
    background: linear-gradient(123.89deg, #FAC01E 8.56%, #FC762A 86.47%);
    border-radius: 50px;
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    text-transform: capitalize;
    margin-left: 10px;
}

.explore_header .bulk_selected .right button:disabled {
    opacity: 0.6;
}

/* View css */
/*@media (min-width: 1440px) {*/
/*    .my_account {*/
/*        margin: auto;*/
/*        max-width: 1400px;*/
/*    }*/
/*}*/

@media (min-width: 1450px) {
    .activity_table {
        display: flex;
        justify-content: center;
    }

    .activity_table > div {
        position: relative;
        margin: auto;
        max-width: 1450px;
        min-width: 1200px;
    }
}

@media (max-width: 1350px) {
    .my_account .horizontal_tabs.sticky div[role='tablist'] {
        justify-content: flex-end;
    }
}

@media (min-width: 1536px) {
    .my_account.fix .horizontal_tabs {
        margin: auto;
        max-width: 1500px;
    }

    .my_account {
        /*max-width: 1500px;*/
        /*margin: auto;*/
    }

    .account_explore_header {
        position: sticky;
        top: 158px;
        /* background: #131618; */
        z-index: 2;
        background: #191D20;
        border-width: 1px 0;
        border-style: solid;
        border-color: #22272B;
    }

    .account_active_card {
        background: #131618;
    }

    .account_active_card > div {
        max-width: 1500px;
        margin: auto;
    }

    .account_active_card .account_explore_header {

    }

    .account_explore_header .explore_header {
        max-width: 1500px;
        margin: auto;
        padding: 20px 0;
    }

    .account_explore_main {
        background: #131618;
    }

    .account_explore_main > div  {
        max-width: 1500px;
        margin: auto;
        padding: 20px 0;
    }

    .show_active_card .lists.owner_nfts {
        padding-right: 20px;
    }
}

@media (max-width: 1050px) {
    .my_account .horizontal_tabs .tab {
        padding: 6px 10px;
        font-size: 18px;
    }

    .my_account .horizontal_tabs .address {
        font-size: 16px;
        padding: 6px 10px;
    }

    .my_account .address .icon {
        margin-left: 15px;
        width: 20px;
    }
}

@media (max-width: 1024px) {
    .my_account > h2 {
        font-size: 34px;
    }

    .my_account .address {
        font-size: 20px;
        padding: 10px 20px;
    }

    .my_account .explore_header {
        /*top: 132px;*/
    }
}

@media (max-width: 960px) {
    .my_account .address {
        font-size: 26px;
    }
}

@media (max-width: 860px) {
    .my_account .address {
        font-size: 24px;
    }

    .horizontal_tabs.sticky .address_div {
        display: none;
    }

    .my_account .horizontal_tabs.sticky div[role='tablist'] {
        justify-content: center;
        padding: 10px 20px;
    }

    .my_account .explore_header {
        top: 138px;
        padding: 10px;
        min-height: unset;
    }
}

@media (max-width: 800px) {
    .my_account .address {
        font-size: 20px;
        padding: 10px 15px;
    }

    .my_account .address .icon {
        margin-left: 20px;
    }

    .my_account .explore_header {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 12px;
    }
}

@media (max-width: 768px) {
    .my_account > h2 {
        margin-bottom: 20px;
    }

    .my_account .address {
        margin-bottom: 40px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: max-content;
    }

    .my_account .address p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .my_account .horizontal_tabs .address {
        font-size: 14px;
    }

    .my_account .horizontal_tabs .address > span {
        width: 100px;
    }

    .my_account .address .icon {
        margin-left: 10px;
        flex-shrink: 0;
    }

    /* Bulk Select */
    .explore_header .bulk_selected {
        padding-right: 20px;
    }

    .my_account .explore_main.show_active_card .explore {
        display: none;
    }

    .explore_header .bulk_selected .left .cancel_button {
        background: unset;
        min-width: auto;
        margin-right: 0;
        padding: 0;
    }

    .explore_header .bulk_selected .left .cancel_button > span {
        display: none;
    }

    .explore_header .bulk_selected .left .deselect_button label > span:last-child {
        display: none;
    }

    .explore_header .bulk_selected .left .deselect_button {
        border: unset;
        background: unset;
        min-width: auto;
        height: auto;
    }

    .explore_header .bulk_selected .left .deselect_button > div {
        padding: 0;
        width: 19px;
    }

    .explore_header .bulk_selected .left .deselect_button label > span:first-child {
        padding: 0;
    }

    .explore_header .bulk_selected .left .deselect_button label {
        margin-left: 0;
    }

    .explore_header .bulk_selected .left h2 > span {
        display: none;
    }

    .explore_header .bulk_selected .left > span.selected_bulk {
        display: none;
    }
}

@media (max-width: 600px) {
    .activity_table table tbody tr td div {
        text-align: center;
    }

    .activity_table table tbody tr td div .token_amount,
    .activity_table table tbody tr td div .from_value,
    .activity_table table tbody tr td div .time,
    .activity_table table thead tr th:nth-child(2) {
        width: 100%;
        max-width: 100%;
        text-align: center;
    }

    .my_account .address {
        font-size: 16px;
        padding: 6px 10px;
        width: 90%;
        justify-content: center;
    }

    /* Tabs CSS */
    .my_account .horizontal_tabs .tab {
        font-size: 16px;
        padding: 6px 14px;
    }

    .my_account .horizontal_tabs div[role='tablist'],
    .my_account .horizontal_tabs.sticky div[role='tablist'] {
        padding: 10px 20px;
        overflow: auto;
        margin: 0 20px;
        justify-content: flex-start;
    }

    /* Horizontal scroll */
    .my_account .horizontal_tabs div[role='tablist']::-webkit-scrollbar,
    .my_account .horizontal_tabs div[role='tablist']::-webkit-scrollbar-thumb {
        height: 4px;
        background: #191d21;
    }

    .my_account .horizontal_tabs.sticky div[role='tablist']::-webkit-scrollbar,
    .my_account .horizontal_tabs.sticky div[role='tablist']::-webkit-scrollbar-thumb {
        height: 4px;
        background: #141618;
    }

    .my_account .horizontal_tabs div[role='tablist']:hover::-webkit-scrollbar {
        height: 4px;
        background: #3F5A6F;
    }

    .my_account .horizontal_tabs div[role='tablist']:hover::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-clip: content-box;
        background: linear-gradient(91.79deg, #FAC01E 3.15%, #FC762A 85.66%);
    }

    /* Horizontal scroll */
    .explore_header .bulk_selected .right button {
        margin-left: 6px;
        font-size: 14px;
        padding: 4px 8px;
        min-width: auto;
    }

    .explore_header .bulk_selected .right button:first-child {
        margin-left: 0;
    }
}

@media (max-width: 425px) {
    .my_account .address {
        padding: 6px 10px;
    }

    .my_account .horizontal_tabs div[role='tablist'] {
        padding: 10px;
        margin: 0 10px;
    }

    .my_account .explore_header {
        justify-content: center;
    }

    .my_account .horizontal_tabs .tab {
        font-size: 14px;
    }

    .my_account .horizontal_tabs .address {
        display: none;
    }

    .activity_table {
        padding: 20px;
    }

    .my_account .horizontal_tabs > div > span:last-child {
        display: none;
    }

    .activity_table table thead tr th div {
        font-size: 12px;
    }

    .explore_header .bulk_selected {
        flex-direction: column;
        height: auto;
        box-shadow: unset;
        padding: 14px 10px 10px;
    }

    .explore_header .bulk_selected .left {
        margin-bottom: 6px;
    }

    .explore_header .bulk_selected .left .deselect_button label > span:last-child {
        display: unset;
    }

    .explore_header .bulk_selected .left .deselect_button > div {
        width: auto;
    }
}

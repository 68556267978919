.collect_now_page {
    padding: 40px;
}

.collect_now_page .collect_now_header h2 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 140%;
    text-align: left;
    color: #FFFFFF;
}

.collect_now_page .collect_now_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.collect_now_page .collect_now_header .explore_more button {
    background: rgba(33, 38, 43, .4);
    border-radius: 100px;
    padding: 5px 20px;
}

.collect_now_page .collect_now_header .explore_more button p {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    text-align: center;
    background: linear-gradient(91.79deg, #fac01e 3.15%, #fc762a 85.66%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: initial;
}

.collect_now_page .collect_now_header .explore_more button svg {
    margin-left: 10px;
}

@media (min-width: 1536px) {
    .collect_now_page {
        max-width: 1500px;
        margin: 20px auto;
        width: 1500px;
        padding: 40px 0;
    }
}

@media (max-width: 768px) {
    .collect_now_page {
        padding: 20px;
    }
}

@media (max-width: 425px) {
    .collect_now_page .collect_now_header > h2 {
        font-size: 20px;
    }
}

.launchpad_page {
    padding: 40px;
}

.launchpad_page .launchpad_list {
    background: none;
}

.launchpad_page > h2 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 140%;
    text-align: left;
    color: #FFFFFF;
}

.launchpad_page .launchpad_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.launchpad_page .launchpad_header .launchpad_tabs button:first-child {
    border-radius: 100px 0 0 100px;
}

.launchpad_page .launchpad_header .launchpad_tabs button:last-child {
    border-radius: 0 100px 100px 0;
}

.launchpad_page .launchpad_header .launchpad_tabs button {
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    letter-spacing: 0.005em;
    color: #828282;
    background: rgba(33, 38, 43, 0.4);
    border: 1px solid #2F363D;
    text-transform: capitalize;
    padding: 8px 20px;
}

.launchpad_page .launchpad_header .launchpad_tabs .active_tab {
    background: linear-gradient(91.79deg, #fac01e 3.15%, #fc762a 85.66%);
    color: #000000;
}

.launchpad_page .explore_more button {
    background: rgba(33, 38, 43, .4);
    border-radius: 100px;
    padding: 5px 20px;
}

.launchpad_page .explore_more button p {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    text-align: center;
    background: linear-gradient(91.79deg, #fac01e 3.15%, #fc762a 85.66%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: initial;
}

.launchpad_page .explore_more button svg {
    margin-left: 10px;
}

.launchpad_page .launchpad_page_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/*.launchpad_page .launchpad_page_header .explore_more {*/
/*    visibility: hidden;*/
/*}*/

/* List CSS */
.launchpad_list {
    padding: 20px 0;
}

.launchpad_list .list_details {
    background: rgba(33, 38, 43, 0.4);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    position: relative;
}

.launchpad_list .list_details .sale_period {
    position: absolute;
    right: 0;
    top: 0;
}

.launchpad_list .list_details .collection_image {
    width: 80px;
    height: 80px;
}

.launchpad_list .list_details .inline_video_section {
    border-radius: 5px;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    width: 100%;
    padding-bottom: 100%;
    position: relative;
}

.launchpad_list .list_details .inline_video_section video {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
}

.launchpad_list .list_details .inline_video {
    width: 100%;
    height: 100%;
}

.launchpad_list .list_details .section1 {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 75px;
    justify-content: space-between;
    width: 72%;
}

.launchpad_list .list_details .section1 .row1 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 65%;
}

.launchpad_list .list_details .section1 .row1 > p {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    font-feature-settings: 'zero' on, 'ss02' on;
    color: #F2F2F2;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.launchpad_list .list_details .section1 .row1 img {
    margin-left: 10px;
}

.launchpad_list .list_details .section1 .row2 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #BDBDBD;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 70%;
}

.launchpad_list .list_details .section1 .row2 > span {
    flex-shrink: 0;
}

.launchpad_list .list_details .section1 .row2 > div {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    font-feature-settings: 'zero' on, 'ss02' on;
    color: #FFFFFF;
    margin-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.launchpad_list .list_details .section1 .row2 > div img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.launchpad_list .list_details:hover .collection_image,
.launchpad_list .list_details:hover .sale_period {
    display: none;
}

.launchpad_list .list_details:hover .section1 {
    display: none;
}

.launchpad_list .list_details:hover:before {
    content: '';
    position: absolute;
    background: #141414;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 10px;
}

.launchpad_list .list_details:hover:after {
    content: '';
    background: linear-gradient(91.79deg, #FAC01E 3.15%, #FC762A 85.66%);
    position: absolute;
    top: -2px;
    left: -2px;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    z-index: -2;
    border-radius: 10px;
}

/* Hover Card */
.launchpad_list .list_details .on_hover {
    display: none;
}

.launchpad_list .list_details:hover .on_hover {
    display: flex;
    height: 80px;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
}

.launchpad_list .list_details:hover .on_hover .collection_info {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    font-feature-settings: 'zero' on, 'ss02' on;
    color: #F2F2F2;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.launchpad_list .list_details:hover .on_hover .collection_info span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: max-content;
}

.launchpad_list .list_details:hover .on_hover .collection_info img {
    margin-left: 20px;
}

.launchpad_list .list_details:hover .on_hover .hover_price {
    position: absolute;
    border-radius: 5px;
    bottom: 14px;
    right: 20px;
    left: 20px;
    z-index: 1;
    display: flex;
    align-items: center;
    background: #161C21;
}

.launchpad_list .list_details:hover .on_hover .hover_price .left_section {
    width: max-content;
    min-width: 45%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 10px;
    background: #161C21;
    height: 40px;
    border-radius: 10px 0 0 10px;
    max-width: 50%;
}

.launchpad_list .list_details:hover .on_hover .hover_price .left_section img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.launchpad_list .list_details:hover .on_hover .hover_price .left_section div {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    font-feature-settings: 'zero' on, 'ss02' on;
    background: linear-gradient(123.89deg, #FAC01E 8.56%, #FC762A 86.47%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.launchpad_list .list_details:hover .on_hover .hover_price .arrow_right {
    height: 40px;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 23px solid #161C21;
}

.launchpad_list .list_details:hover .on_hover .hover_price .right_section {
    /* background: linear-gradient(123.89deg, #FAC01E 8.56%, #FC762A 86.47%); */
    border-radius: 0 5px 5px 0;
    /* padding: 8px 0; */
    width: 45%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.launchpad_list .list_details:hover .on_hover .hover_price .right_section button {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    font-feature-settings: 'zero' on, 'ss02' on;
    background: linear-gradient(180deg, #0E151B 0%, #000000 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: initial;
    height: 100%;
    min-height: 100%;
    position: absolute;
    width: 100%;
    left: 0;
    border-radius: 5px;
    padding: 0 2px 0 55%;
}

.launchpad_list .list_details:hover .on_hover .hover_price .right_section button svg {
    fill: #000000;
    width: 20px;
    height: 20px;
    margin-left: 2px;
}

.launchpad_list .list_details:hover .on_hover .hover_price:after {
    content: '';
    background: linear-gradient(91.79deg, #FAC01E 3.15%, #FC762A 85.66%);
    position: absolute;
    top: -2px;
    left: -2px;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    z-index: -2;
    border-radius: 10px;
}

@media (max-width: 768px) {
    .launchpad_page {
        padding: 20px;
    }
}

@media (max-width: 425px) {
    .launchpad_page .launchpad_page_header .explore_more {
        visibility: unset;
    }

    .launchpad_page .explore_more button {
        display: none;
    }

    .launchpad_page .launchpad_header {
        flex-direction: column;
    }

    .launchpad_page .launchpad_page_header .explore_more button {
        display: flex;
    }

    .launchpad_page .launchpad_header .launchpad_tabs button {
        font-size: 16px;
        padding: 8px 10px;
    }

    .launchpad_page .launchpad_page_header > h2 {
        font-size: 20px;
    }

    .home_page .header .explore_more button {
        padding: 5px 10px;
    }

    .home_page .header .explore_more button p {
        font-size: 16px;
    }
}

.auctions_page {
    padding: 40px;
}

.auctions_page > h2 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 140%;
    text-align: left;
    color: #FFFFFF;
}

.auctions_page .auctions_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.auctions_page .auctions_header .auctions_tabs button:first-child {
    border-radius: 100px 0 0 100px;
}

.auctions_page .auctions_header .auctions_tabs button:last-child {
    border-radius: 0 100px 100px 0;
}

.auctions_page .auctions_header .auctions_tabs button {
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    letter-spacing: 0.005em;
    color: #828282;
    background: rgba(33, 38, 43, 0.4);
    border: 1px solid #2F363D;
    text-transform: capitalize;
    padding: 8px 20px;
}

.auctions_page .auctions_header .auctions_tabs button:nth-child(2) {
    border-radius: unset;
}

.auctions_page .auctions_header .auctions_tabs .active_tab {
    background: linear-gradient(91.79deg, #fac01e 3.15%, #fc762a 85.66%);
    color: #000000;
}

.auctions_page .explore_more button {
    background: rgba(33, 38, 43, .4);
    border-radius: 100px;
    padding: 5px 20px;
}

.auctions_page .explore_more button p {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    text-align: center;
    background: linear-gradient(91.79deg, #fac01e 3.15%, #fc762a 85.66%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    text-transform: initial;
}

.auctions_page .explore_more button svg {
    margin-left: 10px;
}

.auctions_page .auctions_page_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.auctions_page .auctions_page_header .explore_more {
    visibility: hidden;
}

@media (max-width: 768px) {
    .auctions_page {
        padding: 20px;
    }
}

@media (max-width: 425px) {
    .auctions_page .auctions_header {
        flex-direction: column;
    }

    .auctions_page .auctions_page_header .explore_more {
        visibility: unset;
    }

    .auctions_page .auctions_header .explore_more {
        display: none;
    }

    .auctions_page .auctions_header .auctions_tabs button {
        font-size: 16px;
        padding: 8px 10px;
    }

    .auctions_page .auctions_page_header > h2 {
        font-size: 20px;
    }

    .auctions_page .explore_more button {
        padding: 5px 10px;
    }

    .auctions_page .explore_more button p {
        font-size: 16px;
    }
}

.itc_page.explore_main .explore {
    height: unset;
}

.itc_page {
    display: flex;
    align-items: center;
    justify-content: center;
}

.itc_page .header {
    border-width: 1px 0;
    border-style: solid;
    border-color: #22272B;
    padding: 30px 0;
    background: #191d21;
}

.itc_page .header h2 {
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 50px;
    text-align: center;
    color: #F2F2F2;
    margin: 0 0 20px;
}

.itc_page .itc_section {
    background: #06060626;
    backdrop-filter: blur(10px);
}

.itc_page .itc_nft_section {
    border-radius: 10px;
}

.itc_page .itc_nft_section .header > h2 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    color: #F3D2FF;
}

.itc_page .itc_nft_section .itc_nft_list,
.itc_page .itc_my_campaign_section .itc_nft_list {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-gap: 24px;
    padding: 20px 40px;
}

.itc_page .itc_nft_section .itc_nft_list .no_data,
.itc_page .itc_my_campaign_section .itc_nft_list .no_data {
    grid-column: span 1;
}

/* itc_filters */

.itc_page .itc_filters {
    background: #191D20;
}

.itc_page .itc_filters .itc_filters_inner_div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 40px 20px 40px;
    border-width: 1px 0;
    border-style: solid;
    border-color: #22272B;
}

.itc_page .itc_filters .itc_filters_inner_div .grid_list_view {
    display: flex;
}

.itc_page .itc_filters .left_section {

}

.itc_page .itc_filters .left_section .tabs_section {

}

.itc_page .itc_filters .left_section .tabs_section button {
    background: rgba(33, 38, 43, 0.4);
    border: 1px solid #2F363D;
    border-radius: 50px;
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #CECECE;
    text-transform: capitalize;
    padding: 8px 16px;
    margin-right: 10px;
}

.itc_page .itc_filters .left_section .tabs_section .active_tab {
    background: linear-gradient(123.89deg, #FAC01E 8.56%, #FC762A 86.47%);
    border: 1px solid #2F363D;
    border-radius: 50px;
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    color: #121010;
}

.itc_page .itc_filters .grid_list_view button {
    background: rgba(33, 38, 43, 0.4);
    border: 1px solid #2F363D;
    min-width: auto;
    padding: 8px 12px;
}

.itc_page .itc_filters .grid_list_view button svg {
    fill: #CECECE;
}

.itc_page .itc_filters .grid_list_view .active_view {
    background: linear-gradient(123.89deg, #FAC01E 8.56%, #FC762A 86.47%);
}

.itc_page .itc_filters .grid_list_view .active_view svg {
    fill: black;
}

.itc_page .itc_filters .grid_list_view button:first-child {
    border-radius: 50px 0 0 50px;
}

.itc_page .itc_filters .grid_list_view button:last-child {
    border-radius: 0 50px 50px 0;
}

.itc_page .campaigns_list_section .itc_list_section > div > div:first-child div button svg,
.itc_page .campaigns_list_section .itc_my_list_section > div > div:first-child div button svg,
.itc_page .flix_drops .itc_list_section > div > div:first-child div button svg {
    fill: #FFFFFF;
}

/*banner section*/
.itc_page .banner_section {
    display: flex;
    width: 100%;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    background: radial-gradient(1419.17% 66.3% at 51.46% 50%, #FAC01E 0%, #FC502A 100%);
}

.itc_page .banner_section .section1 {
    display: flex;
    padding: 12px 16px 12px 32px;
    justify-content: center;
    align-items: center;
    gap: 56px;
    border-radius: 100px;
    background: rgba(0, 0, 0, 0.80);
}

.itc_page .banner_section .section1 .left_section {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex-wrap: wrap;
}

.itc_page .banner_section .section1 .left_section p {
    color: #FFF;
    font-family: 'Work Sans', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    flex-shrink: 0;
}

.itc_page .banner_section .section1 .left_section p span {
    color: #47BFEC;
    text-decoration-line: underline;
    cursor: pointer;
}

.itc_page .banner_section .section1 .left_section .flix_section {
    display: flex;
    align-items: center;
    gap: 3px;
}

.itc_page .banner_section .section1 .left_section .flix_section p {
    color: #FFF;
    font-family: 'Work Sans', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.itc_page .banner_section .section1 button {
    display: flex;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    background: linear-gradient(92deg, #FAC01E 3.16%, #FC502A 119.92%);

    color: #000;
    text-align: center;
    font-variant-numeric: slashed-zero;
    font-feature-settings: 'ss02' on;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: none;
    gap: 8px;
}

@media (min-width: 1536px) {
    /*.itc_page .itc_section {*/
    /*    max-width: 1500px;*/
    /*    margin: auto;*/
    /*}*/
}

@media (min-width: 600px) {
    .itc_page .itc_nft_section .itc_nft_list {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .itc_page .itc_my_campaign_section .itc_nft_list {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

@media (min-width: 900px) {
    .itc_page .itc_nft_section .itc_nft_list {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .itc_page .itc_my_campaign_section .itc_nft_list {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    .itc_page .itc_nft_section .itc_nft_list .no_data {
        grid-column: span 2;
    }
}

@media (min-width: 1200px) {
    .itc_page .itc_nft_section .itc_nft_list {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .itc_page .itc_my_campaign_section .itc_nft_list {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
}

@media (min-width: 1536px) {
    .itc_page .itc_nft_section .itc_nft_list {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .itc_page .itc_my_campaign_section .itc_nft_list {
        grid-template-columns: repeat(5, minmax(0, 1fr));
    }

    .itc_page .header h2 {
        max-width: 1500px;
        margin: 0 auto 20px auto;
    }

    .itc_page .itc_filters .itc_filters_inner_div,
    .itc_page .itc_nft_section {
        max-width: 1500px;
        margin: auto;
        padding: 20px 0;
    }
}

/*@media (min-width: 1640px) {*/
/*    .itc_page .header h2 {*/
/*        max-width: 1600px;*/
/*        margin: 0 auto 20px auto;*/
/*    }*/

/*    .itc_page .itc_filters .itc_filters_inner_div,*/
/*    .itc_page .itc_nft_section {*/
/*        max-width: 1600px;*/
/*        margin: auto;*/
/*    }*/
/*}*/

@media (max-width: 1100px) {
    .itc_page .banner_section .section1 {
        gap: 30px;
    }

    .itc_page .banner_section .section1 .left_section p {
        font-size: 22px;
    }

    .itc_page .banner_section .section1 .left_section .flix_section p {
        font-size: 22px;
    }

    .itc_page .banner_section .section1 .left_section .flix_section svg {
        width: 40px;
        height: 40px;
    }

    .itc_page .banner_section .section1 button {
        padding: 10px 14px;
    }
}

@media (max-width: 1000px) {
    .itc_page .banner_section .section1 {
        gap: 22px;
    }

    .itc_page .banner_section .section1 .left_section p {
        font-size: 18px;
    }

    .itc_page .banner_section .section1 .left_section .flix_section p {
        font-size: 18px;
    }

    .itc_page .banner_section .section1 .left_section .flix_section svg {
        width: 36px;
        height: 36px;
    }

    .itc_page .banner_section .section1 button {
        padding: 8px 12px;
        font-size: 16px;
    }
}

@media (max-width: 800px) {
    .itc_page .itc_filters .left_section .tabs_section button {
        font-size: 16px;
        padding: 6px 12px;
    }

    .itc_page .banner_section .section1 {
        flex-direction: column;
        gap: 10px;
        padding: 14px 40px;
    }

    .itc_page .banner_section .section1 button {
        padding: 12px 16px;
        font-size: 18px;
    }
}

@media (max-width: 700px) {
    .itc_page .itc_filters .itc_filters_inner_div {
        padding: 10px 20px;
    }

    .itc_page .itc_nft_section .itc_nft_list,
    .itc_page .itc_my_campaign_section .itc_nft_list {
        padding: 20px;
    }
}

@media (max-width: 640px) {
    .itc_page .itc_filters .left_section .tabs_section button {
        font-size: 14px;
    }
}

@media (max-width: 600px) {
    .itc_page .itc_filters .itc_filters_inner_div {
        flex-direction: column;
    }

    .itc_page .itc_filters .left_section {
        margin-right: 0;
        margin-bottom: 10px;
        width: 100%;
    }

    .itc_page .itc_filters .left_section .tabs_section {
        overflow: auto;
        width: 100%;
        flex: 1 1 auto;
        white-space: nowrap;
    }

    /* Horizontal scroll */
    .itc_page .itc_filters .left_section .tabs_section::-webkit-scrollbar,
    .itc_page .itc_filters .left_section .tabs_section::-webkit-scrollbar-thumb {
        height: 4px;
        background: #191d21;
    }

    .itc_page .itc_filters .left_section .tabs_section:hover::-webkit-scrollbar {
        height: 4px;
        background: #3F5A6F;
    }

    .itc_page .itc_filters .left_section .tabs_section:hover::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-clip: content-box;
        background: linear-gradient(91.79deg, #FAC01E 3.15%, #FC762A 85.66%);
    }
}

@media (max-width: 500px) {
    .itc_page .banner_section .section1 .left_section p {
        flex-shrink: unset;
    }

    .itc_page .banner_section .section1 {
        border-radius: 30px;
        padding: 14px 20px;
    }
}

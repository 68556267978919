.welcome_page {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 40px;
    position: relative;
}

/* Left Section Css */
.welcome_page .circle1 {
    position: fixed;
    bottom: 0;
    left: 0;
    background: linear-gradient(227.59deg, #195E7C 18.66%, #1AAB88 91.89%);
    filter: blur(130px);
    width: 270px;
    height: 300px;
    border-radius: 50px;
}

.welcome_page .circle2 {
    position: fixed;
    top: 0;
    right: 0;
    background: linear-gradient(227.59deg, #C22E75 18.66%, #1A9AAB 91.89%);
    border: 1px solid #000000;
    filter: blur(130px);
    width: 250px;
    height: 250px;
    border-radius: 50px;
}

.welcome_page .left_section {
    padding: 50px;
    z-index: 9;
}

.welcome_page .left_section > h2 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 140%;
    color: #FFFFFF;
    text-align: left;
}

.welcome_page .left_section .actions {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.welcome_page .left_section .actions button svg,
.flix_drop_banner .explore_button svg {
    margin-left: 10px;
    width: 26px;
}

.welcome_page .left_section .actions button p,
.flix_drop_banner .explore_button p {
    background: linear-gradient(180deg, #0e151b, #000);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
    text-transform: capitalize;
}

.welcome_page .left_section .actions .explore_button,
.flix_drop_banner .explore_button {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    text-align: center;
    background: linear-gradient(91.79deg, #fac01e 3.15%, #fc762a 85.66%);
    border-radius: 100px;
    padding: 10px 20px;
}

.welcome_page .left_section .actions .create_button {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    text-align: center;
    position: relative;
    padding: 10px 25px;
    text-transform: capitalize;
    margin-left: 20px;
}

.welcome_page .left_section .actions .create_button p {
    background: linear-gradient(91.79deg, #fac01e 3.15%, #fc762a 85.66%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: block;
    width: -webkit-max-content;
    width: max-content;
}

.welcome_page .left_section .actions .create_button:before {
    content: "";
    position: absolute;
    background: #191d21;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 100px;
}

.welcome_page .left_section .actions .create_button:after {
    content: "";
    background: linear-gradient(91.79deg, #fac01e 3.15%, #fc762a 85.66%);
    position: absolute;
    top: -2px;
    left: -2px;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    z-index: -2;
    border-radius: 100px;
}

/* Right Section Css */
.welcome_page .right_section {
    z-index: 9;
}

.welcome_page .right_section .carousel_page {
    border-radius: 50px;
    position: relative;
    height: 440px;
}

.welcome_page .right_section .carousel_page .auto_play {
    position: relative;
    width: 440px;
    height: 440px;
    cursor: pointer;
}

.welcome_page .right_section .carousel_page .auto_play:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(0deg, rgba(11, 11, 11, 0.88) 15.96%, rgba(11, 11, 11, 0) 82.43%);
    border-radius: 30px;
}

.welcome_page .right_section .carousel_page .auto_play img {
    width: 100%;
    height: 100%;
    max-height: 440px;
}

.welcome_page .right_section .carousel_page > div:first-child {
    border-radius: 30px;
}

.welcome_page .right_section .carousel_page > div:first-child img {
    border-radius: 30px;
}

.welcome_page .right_section .carousel_page > div.no_data {
    height: 100%;
}

.welcome_page .right_section .carousel_page .slider_info {
    background: rgba(126, 126, 126, 0.1);
    backdrop-filter: blur(25px);
    border-radius: 30px;
    position: absolute;
    bottom: 10px;
    left: 10px;
    right: 10px;
    padding: 20px;
    cursor: pointer;
}

.welcome_page .right_section .carousel_page .slider_info > h2 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;
    text-align: center;
    background: linear-gradient(91.79deg, #fac01e 3.15%, #fc762a 85.66%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
    text-transform: capitalize;
    margin: 0;
}

.welcome_page .right_section .carousel_page .slider_info .slider_label {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 140%;
    text-align: center;
    color: #FFFFFF;
    margin: 10px 0;
}

.welcome_page .right_section .carousel_page .slider_info .slider_token {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 39px;
    font-feature-settings: 'zero' on;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
}

.welcome_page .right_section .carousel_page .slider_info .slider_token img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.welcome_page .right_section .carousel_page .stepper {
    background: none;
    margin-top: 20px;
}

.welcome_page .right_section .carousel_page .stepper button:disabled svg {
    fill: rgba(33, 38, 43, 0.4);
}

.welcome_page .right_section .carousel_page .stepper > button {
    display: none;
}

.welcome_page:hover .right_section .carousel_page .stepper > button {
    display: unset;
}

.welcome_page .right_section .carousel_page .stepper .prev_button {
    position: absolute;
    top: 50%;
    left: -60px;
}

.welcome_page .right_section .carousel_page .stepper .next_button {
    position: absolute;
    top: 50%;
    right: -60px;
}

.welcome_page .right_section .carousel_page .stepper button svg {
    width: 18px;
    height: 20px;
    fill: #4F4F4F;
}

.welcome_page .right_section .carousel_page .stepper > div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.welcome_page .right_section .carousel_page .stepper > div div {
    background: rgba(33, 38, 43, 0.4);
    border-radius: 20px;
    width: 70px;
    height: 3px;
}

.welcome_page .right_section .carousel_page .stepper.active_stepper0 > div > div:nth-child(1) {
    background: #4F4F4F;
}

.welcome_page .right_section .carousel_page .stepper.active_stepper1 > div > div:nth-child(2) {
    background: #4F4F4F;
}

.welcome_page .right_section .carousel_page .stepper.active_stepper2 > div > div:nth-child(3) {
    background: #4F4F4F;
}

.welcome_page .right_section .carousel_page .stepper.active_stepper3 > div > div:nth-child(4) {
    background: #4F4F4F;
}

.welcome_page > h2 {
    display: none;
}

@media (min-width: 1536px) {
    .welcome_page {
        max-width: 1500px;
        margin: auto;
        width: 1500px;
        padding: 40px 0;
    }
}

@media (max-width: 1024px) {
    .welcome_page .right_section .carousel_page,
    .welcome_page .right_section .carousel_page .auto_play {
        width: 350px;
        height: 350px;
    }

    .welcome_page .left_section {
        padding-left: 0;
        padding-right: 0;
    }
}

@media (max-width: 768px) {
    .welcome_page {
        flex-direction: column-reverse;
        padding: 20px;
    }

    .welcome_page .right_section {
        padding: 20px 0 50px;
    }

    .welcome_page .right_section {
        padding: 50px 0;
    }

    .welcome_page .left_section .actions {
        justify-content: center;
    }

    .welcome_page .left_section > h2 {
        display: none;
    }

    .welcome_page > h2 {
        display: inherit;
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 140%;
        color: #FFFFFF;
        text-align: center;
    }
}

@media (max-width: 600px) {
    .welcome_page .left_section > h2 {
        text-align: center;
        font-size: 30px;
    }
}

@media (max-width: 425px) {
    .welcome_page .right_section .carousel_page,
    .welcome_page .right_section .carousel_page .auto_play img {
        width: 100%;
        height: 100%;
    }

    .welcome_page .right_section,
    .welcome_page .right_section .carousel_page .auto_play {
        width: 100%;
    }

    .welcome_page .left_section > h2 {
        text-align: center;
        font-size: 30px;
    }

    .welcome_page .left_section .actions {
        flex-direction: column;
    }

    .welcome_page .left_section .actions button {
        width: 100%;
    }

    .welcome_page .left_section .actions .create_button {
        margin: 20px 0 0 0;
    }

    .welcome_page .right_section .carousel_page .slider_info {
        bottom: 50px;
    }

    .welcome_page .left_section {
        padding: 20px 0;
    }
}

.marketplace_class {
    background: #131618;
}

.explore_main {
    display: flex;
    position: relative;
    background: #131618;
    flex: 1;
}

.marketplace.explore_main .explore {
    height: unset;
}

.explore_main .explore {
    width: 100%;
    height: calc(100vh - 80px);
    flex-shrink: 0;
}

.explore_main.show_filter .explore {
    width: calc(100% - 380px);
}

.explore_main.show_active_card .explore {
    width: calc(100% - 600px);
}

.marketplace_main_div .header_section {
    border-width: 1px 0;
    border-style: solid;
    border-color: #22272B;
    padding: 30px 0;
}

.marketplace_main_div .header_section h2 {
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 50px;
    text-align: center;
    color: #F2F2F2;
    margin: 0 0 20px;
}

.marketplace_main_div .marketplace_tabs {
    display: flex;
    align-items: center;
    justify-content: center;
}

.marketplace_main_div .marketplace_tabs button {
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #CECECE;
    border: none;
    background: none;
    padding: 6px 20px;
}

.marketplace_main_div .marketplace_tabs button p {
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    background: none;
    -webkit-text-fill-color: unset;
}

.marketplace_main_div .marketplace_tabs .active_tab {
    background: #22272B;
    border-radius: 50px;
}

/* search field */
.listing_search .text_field > div {
    border-radius: 50px;
    font-family: 'Sarabun', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    letter-spacing: 0.005em;
    color: #4F4F4F;
    background: #070b0e;
    border: unset !important;
}

.listing_search .text_field .icon {
    fill: #4F4F4F;
    width: 20px;
    opacity: 0.4;
}

.explore_main .listing_search {
    flex: 1;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 10px;
}

.marketplace_main_div .listing_search .text_field {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: auto 10px auto auto;
}

.marketplace_main_div .listing_search .text_field input {
    transition: width 0.5s;
    width: 300px;
}

/*.marketplace_main_div .listing_search .text_field:hover input {*/
/*    width: 250px;*/
/*}*/

/* explore List header css */
.explore_main .tabs_filter {
    position: sticky;
    top: 0;
    z-index: 1;
    background: #131618;
}

.explore_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 40px 10px;
    /*min-height: 92px;*/
}

.explore_head_left {
    font-family: 'Work Sans', sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.explore_head_right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.explore_head_right .active_button svg {
    fill: url(#paint0_linear);
}

.explore_head_right .default_button svg {
    fill: #ffffff;
}

.explore_head_right > button {
    background: #040404;
    border-radius: 5px;
    padding: 8px;
    height: 40px;
    margin-left: 10px;
    width: 40px;
}

.explore_head_right > button:hover {
    background: #040404;
}

.icon_button {
    background: rgba(33, 38, 43, 0.4);
    border: 1px solid #2F363D;
    border-radius: 50px;
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #CECECE;

    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    width: auto;
    min-width: 200px;
    padding: 10px 15px;
}

.icon_button > img {
    /*margin-left: 50px;*/
}

/* list css */
.lists {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-gap: 24px;
    padding: 20px 40px;
    background: #131618;
}

.lists .no_data {
    grid-row: 5;
}

.list_page {
    background: rgba(35, 40, 44, 0.4);
    border: 1px solid #22272B;
    border-radius: 10px;
    padding: 20px 12px;
    animation: 0.3s fadeIn ease-in-out;
    cursor: pointer;
    position: relative;
    z-index: 0;
}

.list_page.active {
    position: relative;
}

.list_page.active:before,
.list_page:hover:before {
    content: '';
    position: absolute;
    background: #191D21;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 10px;
}

.list_page.active:after,
.list_page:hover:after {
    content: '';
    background: linear-gradient(91.79deg, #FAC01E 3.15%, #FC762A 85.66%);
    position: absolute;
    top: -2px;
    left: -2px;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    z-index: -2;
    border-radius: 10px;
}

/* Hover Price css */
.list_page .hover_price {
    display: none;
}

.list_page:hover .hover_price {
    position: absolute;
    border-radius: 5px;
    bottom: 20px;
    right: 12px;
    left: 12px;
    z-index: 1;
    display: flex;
    align-items: center;
    background: #161C21;
}

.list_page:hover .hover_price:after {
    content: '';
    background: linear-gradient(91.79deg, #FAC01E 3.15%, #FC762A 85.66%);
    position: absolute;
    top: -2px;
    left: -2px;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    z-index: -2;
    border-radius: 10px;
}

.list_page:hover .hover_price .arrow_right {
    height: 45px;
    border-top: 22px solid transparent;
    border-bottom: 22px solid transparent;
    border-left: 25px solid #161C21;
}

.list_page:hover .hover_price .left_section {
    width: max-content;
    min-width: 45%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 10px;
    background: #161C21;
    height: 45px;
    border-radius: 10px 0 0 10px;
}

.list_page:hover .hover_price .left_section img,
.list_page:hover .hover_price .left_section svg {
    width: 17px;
    height: 17px;
    margin-right: 8px;
}

.list_page:hover .hover_price .left_section > div {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 17px;
    text-align: center;
    font-feature-settings: 'zero' on, 'ss02' on;
    background: linear-gradient(123.89deg, #FAC01E 8.56%, #FC762A 86.47%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.list_page:hover .hover_price .right_section {
    /*background: linear-gradient(123.89deg, #FAC01E 8.56%, #FC762A 86.47%);*/
    border-radius: 0 5px 5px 0;
    /*padding: 8px 0;*/
    width: 45%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.list_page:hover .hover_price .right_section.notlisted_nft {
    width: 100%;
    border-radius: 5px;
}

.list_page:hover .hover_price .right_section button {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    font-feature-settings: 'zero' on, 'ss02' on;
    background: linear-gradient(180deg, #0E151B 0%, #000000 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: initial;
    padding: 0;
    height: 100%;
    min-height: 100%;
    position: absolute;
    width: 100%;
    left: 0;
    padding-left: 55%;
    border-radius: 5px;
    padding-right: 2px;
}

.show_filter .lists .list_page:hover .hover_price .right_section button {
    font-size: 13px;
}

.list_page:hover .hover_price .right_section.notlisted_nft button {
    position: unset;
    padding-left: unset;
}

.hover_price .place_bid_div {
    height: 100%;
}

/* Hover Price css */

/* List View Hover Price CSS */
.list_view .nft_lists .list_section .section3 .hover_price.list_view_hc {
    /*display: none;*/
}

.market_list_view table tbody tr td .hover_price.list_view_hc {
    display: flex;
    position: absolute;
    border-radius: 5px;
    z-index: 1;
    align-items: center;
    background: #161C21;
    width: 300px;
}

.market_list_view table tbody tr td .hover_price.list_view_hc:after {
    content: '';
    background: linear-gradient(91.79deg, #FAC01E 3.15%, #FC762A 85.66%);
    position: absolute;
    top: -1px;
    left: -1px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    z-index: -2;
    border-radius: 10px;
}

.market_list_view table tbody tr td .hover_price.list_view_hc .arrow_right {
    height: 40px;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 23px solid #161C21;
}

.market_list_view table tbody tr td .hover_price.list_view_hc .left_section {
    width: max-content;
    min-width: 45%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 10px;
    background: #161C21;
    height: 40px;
    border-radius: 10px 0 0 10px;
}

.market_list_view table tbody tr td .hover_price.list_view_hc .left_section img,
.market_list_view table tbody tr td .hover_price.list_view_hc .left_section svg {
    width: 17px;
    height: 17px;
    margin-right: 8px;
}

.market_list_view table tbody tr td .hover_price.list_view_hc .left_section > div {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 17px;
    text-align: center;
    font-feature-settings: 'zero' on, 'ss02' on;
    background: linear-gradient(123.89deg, #FAC01E 8.56%, #FC762A 86.47%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.market_list_view table tbody tr td .hover_price.list_view_hc .right_section {
    border-radius: 0 5px 5px 0;
    width: 45%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.market_list_view table tbody tr td .hover_price.list_view_hc .right_section.notlisted_nft {
    width: 100%;
    border-radius: 5px;
}

.market_list_view table tbody tr td .hover_price.list_view_hc .right_section button {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    font-feature-settings: 'zero' on, 'ss02' on;
    background: linear-gradient(180deg, #0E151B 0%, #000000 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: initial;
    height: 100%;
    min-height: 100%;
    position: absolute;
    width: 100%;
    left: 0;
    border-radius: 5px;
    padding: 0 2px 0 55%;
}

.market_list_view table tbody tr td .hover_price.list_view_hc .right_section.notlisted_nft button {
    position: unset;
    padding-left: unset;
}

.market_list_view table tbody tr td .hover_price.list_view_hc .place_bid_div {
    height: 100%;
}

/* List View Hover Price CSS */

.list_section1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.list_section1_left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.app .list_section1_left .asset_type {
    width: auto;
    padding: 0 10px;
    font-family: 'Work Sans', sans-serif;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #FFFFFF;
    background: rgba(70, 70, 70, 0.5);
    backdrop-filter: blur(10px);
    border-radius: 20px;
    text-transform: uppercase;
}

.list_section1_left > div {
    background: rgba(75, 75, 75, 0.5);
    width: 24px;
    height: 24px;
    backdrop-filter: blur(10px);
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

.list_section1_right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.list_section1_right > span {
    font-family: 'Work Sans', sans-serif;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: #FFFFFF;
    background: rgba(70, 70, 70, 0.5);
    backdrop-filter: blur(10px);
    border-radius: 20px;
    padding: 5px 10px;
}

.list_section1_right > div {
    background: rgba(75, 75, 75, 0.5);
    backdrop-filter: blur(10px);
    border-radius: 20px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    cursor: pointer;
}

.list_section2,
.collection_list .list_page .inline_video_section {
    border-radius: 10px;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    width: 100%;
    padding-bottom: 100%;
    position: relative;
}

.collection_list .list_page .inline_video_section video {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
}

.collection_list .list_page .inline_video {
    width: 100%;
    height: 100%;
}

.list_section2.blur {
    filter: blur(10px);
}

.list_section3 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.list_section3 .list_section3_div {
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: rgba(255, 255, 255, 0.5);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 10px 0 5px 0;
    cursor: pointer;
    width: 100%;
}

.list_section3 .list_section3_div a {
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: rgba(255, 255, 255, 0.5);
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.list_section3 .list_section3_div > span {
    word-break: break-all;
    text-align: left;
}

.list_section3 .list_section3_div:hover {
    text-decoration: underline;
}

.list_section3 .list_section3_div img {
    margin-left: 10px;
    width: 26px;
}

.list_section3 .list_section3_div svg {
    margin-left: 10px;
    fill: #EB5757;
    height: 20px;
    width: 20px;
}

.list_section3 .list_section3_span {
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    font-feature-settings: 'zero' on;
    color: #FFFFFF;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    max-width: 250px;
    cursor: pointer;
    position: relative;
    text-decoration: none;
    width: 100%;
}

.list_section3 .list_section3_span:hover {
    text-decoration: underline;
}

.list_border {
    border: 0 dashed rgba(190, 190, 190, 0.25);
    padding: 10px 0;
}

.list_section4 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
}

.list_section4 > div:first-child {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    width: 45%;
}

.list_section4 > div:last-child {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 55%;
}

.list_section4 > div > span:first-child {
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: rgba(255, 255, 255, 0.5);
    margin-bottom: 5px;
}

.list_section4 > div .lsr_value {
    font-family: 'Work Sans', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    font-feature-settings: 'zero' on;
    color: #DFDFDF;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.list_section4 > div .lsr_value svg,
.list_section4 > div .lsr_value img {
    width: 17px;
    height: 17px;
    margin-right: 10px;
}

.list_section4 > div .lsl_value {
    font-family: 'Work Sans', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    font-feature-settings: 'zero' on;
    color: #DFDFDF;
    max-width: 100%;
    cursor: pointer;
    position: relative;
    text-decoration: none;
}

.list_section4 > div .lsl_value:hover:after {
    content: "";
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    border-width: 0 0 1px;
    border-style: solid;
}

.icon_button .sort_icon {
    display: none;
}

.marketplace .active_card,
.marketplace .filters {
    position: sticky;
    top: 163px;
    overflow-y: auto !important;
    height: calc(100vh - 163px);
}

.marketplace .list_page.active {
    scroll-margin: 163px;
}

@media (min-width: 1536px) {
    /*.marketplace_main_div {*/
    /*    max-width: 1400px;*/
    /*    margin: auto;*/
    /*}*/

    .marketplace_main_div .header_section {
        max-width: 1500px;
        margin: auto;
    }

    .marketplace_class .marketplace {
        max-width: 1500px;
        margin: auto;
    }

    .show_active_card .lists {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}

@media (max-width: 1024px) {
    .list_section3 .list_section3_span {
        max-width: 100%;
    }
}

@media (min-width: 430px) {
    .lists.launchpad_list {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}

@media (min-width: 600px) {
    .lists {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .lists.launchpad_list {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .lists .no_data {
        grid-column: span 2;
    }

    .show_filter .lists {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .show_active_card .lists {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}

@media (min-width: 900px) {
    .lists {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    .lists.launchpad_list {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .lists .no_data {
        grid-column: span 3;
    }

    .show_active_card .lists {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

@media (min-width: 950px) {
    .show_filter .lists {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

@media (min-width: 1200px) {
    .lists {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    .lists.launchpad_list {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    .lists .no_data {
        grid-column: span 4;
    }

    .show_filter .lists {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    .show_active_card .lists {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}

@media (min-width: 1536px) {
    .lists {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    .lists.launchpad_list {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    .lists .no_data {
        grid-column: span 5;
    }

    .show_filter .lists {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    .show_active_card .lists {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}

@media (max-width: 1024px) {
    .explore_head_left {
        font-size: 22px;
    }
}

@media (max-width: 860px) {
    .listing_search .text_field > div {
        font-size: 16px;
    }
}

@media (max-width: 768px) {
    .explore_header {
        min-height: 72px;
    }

    .explore_head_left {
        justify-content: space-between;
        flex-shrink: 0;
    }

    .icon_button {
        width: auto;
        min-width: auto;
    }

    .explore_main .listing_search,
    .icon_button > span,
    .icon_button > img {
        display: none;
    }

    .icon_button .sort_icon {
        display: unset;
    }

    .listing_search .text_field {
        width: 100%;
    }

    .explore_main.show_active_card .explore {
        width: 0;
    }

    .explore_main .explore {
        padding-bottom: 80px;
    }

    .show_active_card .lists {
        display: none;
    }

    .marketplace_main_div .listing_search .text_field input {
        width: 200px;
    }
}

@media (max-width: 600px) {
    .lists {
        padding: 20px;
    }

    .marketplace_main_div .marketplace_tabs button {
        font-size: 16px;
        padding: 6px 14px;
        margin: 0;
    }

    .marketplace_main_div .marketplace_tabs button p {
        font-size: 16px;
    }

    .marketplace_main_div .marketplace_tabs {
        /*overflow: auto;*/
        /*margin: 0 20px;*/
        /*padding: 0 6px;*/
    }

    /* Horizontal scroll */
    .marketplace_main_div .marketplace_tabs::-webkit-scrollbar,
    .marketplace_main_div .marketplace_tabs::-webkit-scrollbar-thumb {
        height: 4px;
        background: #191d21;
    }

    .marketplace_main_div .marketplace_tabs:hover::-webkit-scrollbar {
        height: 4px;
        background: #3F5A6F;
    }

    .marketplace_main_div .marketplace_tabs:hover::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-clip: content-box;
        background: linear-gradient(91.79deg, #FAC01E 3.15%, #FC762A 85.66%);
    }

    /* Horizontal scroll */
}

@media (max-width: 425px) {
    .explore_header {
        padding: 10px 20px;
    }

    .lists {
        padding: 20px;
    }

    .explore_main.show_filter .explore {
        width: 0;
    }

    .app .list_section1_left .asset_type {
        font-size: 10px;
    }

    .explore_head_left {
        font-size: 14px;
    }

    .listing_search .text_field > div {
        font-size: 14px;
    }

    .listing_search .text_field > div input {
        padding: 10px;
    }

    .listing_search .text_field .icon {
        width: 14px;
    }

    .marketplace_main_div .header_section h2 {
        font-size: 26px;
    }

    .marketplace_main_div .marketplace_tabs button p {
        font-size: 14px;
    }

    .show_filter .lists {
        display: none;
    }
}

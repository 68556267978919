.campaigns_list_section {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-gap: 24px;
    padding: 20px 40px;
    animation: 0.3s fadeIn ease-in-out;
}

.camp_listing {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-gap: 24px;
    padding: 20px 0;
}

.camp_listing .grid_view {
    border-radius: 10px;
    border: 1px solid #22272B;
    background: rgba(33, 38, 43, 0.40);
    backdrop-filter: blur(2px);

    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;
    cursor: pointer;
}

.camp_listing .grid_view.hover_grid:hover:after {
    content: '';
    background: linear-gradient(100deg, #12C2E9 0%, #A6CAF3 22.40%, #C471ED 51.06%, #F6A3E0 73.44%, #F64F59 100%);
    position: absolute;
    top: -2px;
    left: -2px;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    z-index: -2;
    border-radius: 10px;
}

.camp_listing .grid_view a {
    text-decoration: none;
}

.camp_listing .flix_drop:before {
    content: '';
    position: absolute;
    background: #181c1f;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 10px;
}

.camp_listing .flix_drop:hover:after {
    content: '';
    background: linear-gradient(100deg, #12C2E9 0%, #A6CAF3 22.40%, #C471ED 51.06%, #F6A3E0 73.44%, #F64F59 100%);
    position: absolute;
    top: -2px;
    left: -2px;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    z-index: -2;
    border-radius: 10px;
}

.camp_listing .grid_view.hover_grid:hover:before {
    content: '';
    position: absolute;
    background: #181c1f;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 10px;
}

.camp_listing .grid_view .image_section {
    position: relative;
    width: 100%;
    background: #000000;
    border-radius: 10px 10px 0 0;
}

.camp_listing .grid_view .image_section .flix_drops_flag {
    border-radius: 8px 8px 0 0;
    position: absolute;
    top: 0;
    background: linear-gradient(100deg, #12C2E9 0%, #A6CAF3 22.40%, #C471ED 51.06%, #F6A3E0 73.44%, #F64F59 100%);
    width: 100%;
    z-index: 1;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.camp_listing .grid_view .image_section .flix_drops_flag > img {
    border-radius: 0;
    width: 95px;
    padding: 10px 2px;
    height: auto;
}

.camp_listing .grid_view .image_section .id {
    position: absolute;
    left: 10px;
    bottom: -10px;
    padding: 6px 12px;
    z-index: 1;

    border-radius: 20px;
    background: #13171B;

    color: #FFF;
    text-align: center;
    font-variant-numeric: slashed-zero;
    font-feature-settings: 'salt' on;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.camp_listing .grid_view .image_section .status {
    position: absolute;
    right: 10px;
    bottom: -10px;
    z-index: 1;
}

.camp_listing .grid_view .image_section .status p {
    border-radius: 100px;
    padding: 4px 10px;

    color: #FFF;
    text-align: center;
    font-variant-numeric: slashed-zero;
    font-feature-settings: 'salt' on;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.camp_listing .grid_view .image_section .status .active {
    background: #27AE60;
}

.camp_listing .grid_view .image_section .status .upcoming {
    background: #2D9CDB;
}

.camp_listing .grid_view .image_section .status .completed {
    color: #12161A;
    background: #F2C94C;
}

.camp_listing .grid_view .image_section .status .stopped {
    background: #EB5757;
}

.camp_listing .grid_view .image_section img,
.camp_listing .grid_view .image_section .inline_video_section,
.camp_listing .grid_view .image_section .list_section2 {
    border-radius: 10px 10px 0 0;
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
    width: 100%;
    /*padding-bottom: 100%;*/
    position: relative;
    height: 100%;
}

.camp_listing .grid_view .image_section .inline_video_section {
    padding-bottom: 100%;
}

.camp_listing .grid_view .image_section .inline_video_section video {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
}

.camp_listing .grid_view .image_section .inline_video {
    width: 100%;
    height: 100%;
}

.camp_listing .grid_view .details_section {
    padding: 20px;
    width: 100%;
}

.camp_listing .grid_view .details_section .section1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.camp_listing .grid_view .details_section .section1 img[alt='verified'] {
    width: 16px;
}

.camp_listing .grid_view .details_section .section1 > span {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
    width: 100%;
}

.camp_listing .grid_view .details_section .section1 .title {
    color: #FFF;
    font-variant-numeric: slashed-zero;
    font-feature-settings: 'ss02' on;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 6px;
}

.camp_listing .grid_view .details_section .section1 .address_section {
    color: #909395;
    text-align: center;
    font-variant-numeric: slashed-zero;
    font-feature-settings: 'ss04' on, 'salt' on;
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-style: italic;
    font-weight: 500;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
}

.camp_listing .grid_view .details_section .section1 .address_section .hash_text {
    color: #F3F3F3;
    text-align: center;
    font-variant-numeric: slashed-zero;
    font-feature-settings: 'ss04' on, 'salt' on;
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 8px;
}

.camp_listing .grid_view .details_section .section1 .address_section .hash_text:hover {
    text-decoration: underline;
}

.camp_listing .grid_view .details_section .section2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 20px 0;
}

.camp_listing .grid_view .details_section .section2 > div {
    color: rgba(255, 255, 255, 0.50);
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 30%;
}

.camp_listing .grid_view .details_section .section2 .clm1 {
    width: 40%;
}

.camp_listing .grid_view .details_section .section2 .clm1 > p:hover {
    cursor: pointer;
    text-decoration: underline;
}

.camp_listing .grid_view .details_section .section2 > div > p {
    color: #FFF;
    text-align: center;
    font-variant-numeric: slashed-zero;
    font-feature-settings: 'ss02' on;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 6px;
}

.camp_listing .grid_view .details_section .section2 > div.clm1 > p {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

.camp_listing .grid_view .details_section .section2 > div > p img {
    width: 16px;
    height: 16px;
    margin-right: 2px;
}

.camp_listing .grid_view .details_section .section2 > div > p span {
    display: flex;
    align-items: center;
    justify-content: center;
}

.camp_listing .grid_view .details_section .section2 > div > p span img {
    margin-right: 4px;
    width: 14px;
}

.camp_listing .grid_view .details_section .progress_bar {
    margin: 20px 0;
}

.camp_listing .grid_view .details_section .progress_bar > span {
    background-color: #303D47;
}

.camp_listing .grid_view .details_section .progress_bar > span > span {
    background: linear-gradient(121deg, #FAC01E 0%, #FC762A 86.98%);
}

.camp_listing .grid_view .details_section .progress_bar .claimed {
    color: #809FB6;
    font-variant-numeric: slashed-zero;
    font-feature-settings: 'ss02' on;
    font-family: 'Inter', sans-serif;
    font-size: 10px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    margin-top: 8px;
}

.camp_listing .grid_view .details_section .actions_section .action .my_campaigns_actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.camp_listing .grid_view .details_section .actions_section .action {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}


.camp_listing .grid_view .details_section .actions_section .action_buttons .stop_button {
    color: #FFFFFF;

    /*background: #EB5757;*/
    border-radius: 4px;
    margin-right: 20px;
    z-index: 2;
}

.camp_listing .grid_view .details_section .actions_section .action_buttons .deposit_button {
    color: #000000;

    /*background: linear-gradient(123.89deg, #FAC01E 8.56%, #FC762A 86.47%);*/
    border-radius: 4px;
    z-index: 2;
}

.camp_listing .grid_view .details_section .actions_section .action button.stop_button > span {
    color: #eb5757;
    background: unset;
    -webkit-background-clip: unset;
    -webkit-text-fill-color: unset;
}

.camp_listing .grid_view .details_section .actions_section .action button > span {
    background: linear-gradient(123.89deg, #fac01e 8.56%, #fc762a 86.47%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.camp_listing .grid_view .details_section .actions_section .action button {
    /*background: linear-gradient(123.89deg, #FAC01E 8.56%, #FC762A 86.47%);*/
    border-radius: 4px;

    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    align-items: center;
    color: #121010;

    text-transform: capitalize;
    padding: 8px 20px;
    width: 100%;
    z-index: 2;
}

.camp_listing .grid_view .details_section .actions_section .action button:before {
    content: "";
    position: absolute;
    background: #1b1b1b;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 4px;
}

.camp_listing .grid_view .details_section .actions_section .action button:after {
    content: "";
    background: linear-gradient(123.89deg, #fac01e 8.56%, #fc762a 86.47%);
    position: absolute;
    top: -2px;
    left: -2px;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    z-index: -2;
    border-radius: 4px;
}

.camp_listing .grid_view .details_section .actions_section .action button.stop_button:after {
    background: #eb5757;
}

.camp_listing .grid_view .details_section .actions_section .action button:disabled {
    color: #000;
    opacity: 0.4;
}

.camp_listing .grid_view .details_section .actions_section .action .counter {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.camp_listing .grid_view .details_section .section3 .left .counter .starts_in,
.camp_listing .grid_view .details_section .actions_section .action .counter .starts_in {
    font-family: 'Inter', sans-serif;
    font-size: 10px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    display: flex;
    align-items: center;
    color: #6FCF97;
}

.camp_listing .grid_view .details_section .section3 .left .counter .ends_in,
.camp_listing .grid_view .details_section .actions_section .action .counter .ends_in {
    font-family: 'Inter', sans-serif;
    font-size: 10px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    display: flex;
    align-items: center;
    color: #EB5757;
}

.camp_listing .grid_view .details_section .actions_section .action .completed {
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #828282;
    margin-top: 10px;
}

.camp_listing .grid_view .details_section .section3 {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.camp_listing .grid_view .details_section .section3 .left {
    color: #809FB6;
    font-variant-numeric: slashed-zero;
    font-feature-settings: 'ss02' on;
    font-family: 'Inter', sans-serif;
    font-size: 10px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
}

.camp_listing .grid_view .details_section .section3 .left > p {
    margin-bottom: 4px;
}

.camp_listing .grid_view .details_section .section3 .left > div {
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.camp_listing .grid_view .details_section .section3 .right button {
    z-index: 1;
    position: relative;
    height: 32px;
    padding: 8px 20px;
    text-transform: capitalize;

    text-align: center;
    font-variant-numeric: slashed-zero;
    font-feature-settings: 'ss02' on;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.camp_listing .grid_view .details_section .section3 .right button:disabled {
    color: #000;
    opacity: 0.4;
}

.camp_listing .grid_view .details_section .section3 .right button > span {
    background: linear-gradient(123.89deg, #fac01e 8.56%, #fc762a 86.47%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.camp_listing .grid_view .details_section .section3 .right button:before {
    content: '';
    position: absolute;
    background: rgb(24 28 30);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 4px;
}

.camp_listing .grid_view .details_section .section3 .right button:after {
    content: '';
    background: linear-gradient(123.89deg, #fac01e 8.56%, #fc762a 86.47%);
    position: absolute;
    top: -1px;
    left: -1px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    z-index: -2;
    border-radius: 4px;
}

@media (min-width: 600px) {
    .camp_listing {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .camp_listing .no_data {
        grid-column: span 2;
    }
}

@media (min-width: 900px) {
    .camp_listing {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    .camp_listing .no_data {
        grid-column: span 3;
    }
}

@media (min-width: 1200px) {
    .camp_listing {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    .camp_listing .no_data {
        grid-column: span 4;
    }
}

@media (min-width: 1536px) {
    .campaigns_list_section {
        max-width: 1500px;
        margin: auto;
        padding: 20px 0;
    }
}

@media (min-width: 1536px) {
    .camp_listing {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    .camp_listing .no_data {
        grid-column: span 4;
    }
}

/*@media (min-width: 1640px) {*/
/*    .campaigns_list_section {*/
/*        max-width: 1600px;*/
/*        margin: auto;*/
/*    }*/
/*}*/

@media (max-width: 420px) {
    .campaigns_list_section {
        padding: 20px;
    }

    .camp_listing .grid_view .details_section {
        padding: 20px 10px;
    }

    .camp_listing .grid_view .details_section .section3 .right button {
        padding: 6px 10px;
    }
}

.nav_bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 30px;
    height: 80px;
}

.navbar_class {
    position: fixed;
    background: linear-gradient(
            180deg, #0E151B 0%, #000000 100%);
    width: 100%;
    height: 80px;
    z-index: 111;
}

.nav_bar .logo_button {
    padding: 0;
}

.nav_bar .logo_button img {
    width: 150px;
}

.nav_bar .right_nav {
    display: flex;
    align-items: center;
}

.nav_bar .right_nav > .icon_button {
    padding: 10px;
}

.nav_bar .right_nav > .icon_button:nth-child(2) {
    margin-right: 10px;
}

.nav_bar .right_nav > .icon_button .icon {
    width: 20px;
}

.nav_bar .price_mode {
    background: #191D20;
    border-radius: 50px;
    position: relative;
    z-index: 1;
    margin: 0 12px;
}

.nav_bar .price_mode:before {
    content: '';
    position: absolute;
    background: #191D20;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 30px;
}

.nav_bar .price_mode:after {
    content: '';
    background: linear-gradient(123.89deg, #FAC01E 8.56%, #FC762A 86.47%);
    position: absolute;
    top: -1px;
    left: -1px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    z-index: -2;
    border-radius: 50px;
}

.nav_bar .price_mode button {
    font-family: "Source Sans Pro", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    background: linear-gradient(180deg, #0E151B 0%, #000000 100%);
    color: #F2F2F2;
    height: 40px;
}

.nav_bar .price_mode button:first-child {
    border-radius: 50px 0 0 50px;
}

.nav_bar .price_mode button:last-child {
    border-radius: 0 50px 50px 0;
}

.nav_bar .price_mode button.active_mode {
    background: linear-gradient(123.89deg, #FAC01E 8.56%, #FC762A 86.47%);
    color: #000000;
}

/* search field */
.navbar_search .text_field > div {
    border-radius: 50px;
    font-family: 'Sarabun', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    letter-spacing: 0.005em;
    color: #4F4F4F;
}

.navbar_search .text_field .icon {
    fill: #4F4F4F;
    width: 20px;
    opacity: 0.4;
}

.nav_bar .navbar_search {
    flex: 1;
}

.navbar_search .text_field {
    width: 80%;
}

/* Tabs */
.right_nav > header {
    background: none;
    width: auto;
    box-shadow: none;
}

.right_nav .tabs_content {
    display: flex;
    height: 80px;
    align-items: center;
}

.right_nav header .tab {
    min-width: auto;
    cursor: pointer;
    border-radius: unset;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.005em;
    text-transform: uppercase;
    color: #F2F2F2;
    opacity: 1;
    padding: 0;
    min-height: 36px;
    height: 36px;
}

.right_nav header .tab p {
    padding: 10px 15px;
}

.right_nav header .tab:hover {
    background: #2F363D;
    border-radius: 50px;
}

.right_nav header .tab.active_tab .text {
    background: linear-gradient(91.79deg, #FAC01E 3.15%, #FC762A 85.66%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.right_nav header .tab .text.recap_text {
    position: relative;
}

.right_nav header .tab .text.recap_text svg {
    position: absolute;
    top: -15.5px;
    right: -29.5px;
}

.right_nav header .tab.active_tab .text.recap_text svg {
    position: absolute;
    top: -15px;
    right: -25px;
}

/* profile popover */
.profile {
    /*margin-left: 25px;*/
}

.profile .profile_section {
    background: linear-gradient(
            90.03deg, #0D141A 0.02%, #0F161C 99.98%);
    border-radius: 50px;
    padding: 10px;
    display: flex;
    align-items: center;
    text-transform: unset;
    z-index: 0;
}

.profile .profile_section:before,
.profile .profile_section:after {
    border-radius: 27.5px;
}

.profile .profile_section:hover {
    background: linear-gradient(
            90.03deg, #0D141A 0.02%, #0F161C 99.98%);
}

.profile_section > img {
    width: 40px;
}

.profile_section .account {
    text-align: right;
    margin: 0 9px;
}

.profile_section .balance {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    color: #FFFFFF;
}

.profile .connect_button {
    background: linear-gradient(
            91.79deg, #FAC01E 3.15%, #FC762A 85.66%);
    border-radius: 50px;
    font-family: 'Spartan', sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.005em;
    padding: 14px 20px;
    color: #000;
}

.connect_button img {
    margin-right: 18px;
}

.connect_button > p {
    margin-top: 3px;
}

.connect_button[disabled] {
    opacity: 0.5;
    text-transform: unset;
}

.profile .profile_img {
    width: 40px;
    height: 40px;
    background: #2D9CDB;
    border-radius: 50px;
}

.nav_bar .create_button {
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    position: relative;
    padding: 10px 25px;
    text-transform: capitalize;
    margin-left: 20px;
}

.nav_bar .create_button > span {
    background: linear-gradient(91.79deg, #FAC01E 3.15%, #FC762A 85.66%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: block;
    width: max-content;
}

.nav_bar .create_button:after {
    content: '';
    background: linear-gradient(91.79deg, #FAC01E 3.15%, #FC762A 85.66%);
    position: absolute;
    top: -2px;
    left: -2px;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    z-index: -2;
    border-radius: 100px;
}

.nav_bar .create_button:before {
    content: '';
    position: absolute;
    background: linear-gradient(
            180deg, #0E151B 0%, #000000 100%);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 100px;
}

.nav_bar .toggle_button,
.nav_bar .expansion_div .apps_div,
.nav_bar .right_nav .back_button {
    display: none;
}

/* Apps Popover */
.apps_div > button {
    padding: 10px;
    color: #FFFFFF;
}

.apps_popover > div:nth-child(3) {
    background: linear-gradient(
            180deg, #0E151B 0%, #000000 100%);
    box-sizing: border-box;
    border-radius: 10px;
    min-width: 200px;
}

.apps_popover .tab {
    position: relative;
}

.apps_popover .tab.stream_pay button svg {
    height: 30px;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: auto;
}

.apps_popover .tab:after {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    width: 70%;
    height: 1px;
    margin-left: 15%;
    background: radial-gradient(40% 515750.02% at 50% 49.99%, #666666 0%, rgba(28, 28, 28, 0) 100%);
}

.apps_popover .tab:last-child:after {
    content: unset;
}

.apps_popover .tab > button {
    width: 100%;
    padding: 15px 20px;
    justify-content: flex-start;
}

.tooltip_text {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.005em;
    text-transform: uppercase;
    color: #F2F2F2;
}

@media (min-width: 1536px) {
    .nav_bar {
        max-width: 1500px;
        margin: auto;
        padding: 10px 0;
    }
}

@media (max-width: 1345px) {
    .nav_bar .right_nav .apps_div {
        display: none;
    }

    .nav_bar .toggle_button,
    .nav_bar .expansion_div .apps_div {
        display: unset;
    }

    .nav_bar .toggle_button > span,
    .nav_bar .expansion_div {
        display: flex;
    }

    .nav_bar .right_nav .back_button {
        display: flex;
        align-items: center;
        cursor: pointer;
        width: 100%;
        justify-content: flex-end;
        padding: 4px 26px 0 0;
        background: unset;
    }

    .nav_bar .right_nav .back_button > button {
        background: rgba(75, 75, 75, 0.5);
        backdrop-filter: blur(10px);
        border-radius: 50px;
        padding: 10px;
    }

    .right_nav .back_button .icon {
        width: 12px;
    }

    .nav_bar .right_nav.show .back_button {
        display: flex;
    }

    .nav_bar .right_nav {
        display: none;
        animation-duration: .3s;
        animation-fill-mode: both;
    }

    .nav_bar .right_nav.show {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        z-index: 1;
        padding: 20px;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(14, 14, 14, 0.2);
        backdrop-filter: blur(40px);
        animation-name: fadeInDown;
    }

    .nav_bar .horizontal_tabs {
        margin-top: 40px;
    }

    .nav_bar .horizontal_tabs .tabs_content {
        display: flex;
        flex-direction: column;
        height: auto;
    }

    .nav_bar .horizontal_tabs .tab {
        width: 200px;
    }

    .nav_bar .create_button {
        margin: 10px 0 18px;
        width: 200px;
    }

    .nav_bar .profile {
        margin: unset;
        width: 200px;
    }

    .profile .profile_section {
        width: 100%;
    }

    .nav_bar .price_mode {
        margin-bottom: 15px;
    }
}

@media (max-width: 940px) {
    .right_nav header .tab {
        padding: 0 10px;
        font-size: 16px;
    }

    .nav_bar .create_button {
        padding: 10px 18px;
        margin-left: 10px;
        font-size: 16px;
    }

    .profile_section .balance {
        font-size: 16px;
    }

    .profile {
        margin-left: 15px;
    }

    .nav_bar .logo_button img {
        width: 140px;
    }

    .profile .profile_img {
        width: 30px;
        height: 30px;
    }
}

@media (max-width: 800px) {
    .right_nav header .tab {
        font-size: 15px;
    }

    .nav_bar .create_button {
        font-size: 15px;
    }

    .profile_section .balance {
        font-size: 15px;
    }
}

@media (max-width: 425px) {
    .nav_bar {
        padding: 10px 20px;
    }
}

.home_page {
    display: flex;
    flex-direction: column;
    position: relative;
    height: calc(100vh - 80px);
}

.home_page .flix_drop_banner {
    width: 1536px;
    margin: 20px auto;
    z-index: 1;
}

.home_page .launchpad_page {
    width: 1536px;
    margin: 20px auto;
}

.auctions_page,
.collect_now_page,
.home_page .mint_manage_page {
    width: 1536px;
    margin: 20px auto;
}

/* request_mint_access */
.home_page .request_mint_access {
    background: linear-gradient(270deg, #1C3547 0%, #1D394F 100%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px;
    border-radius: 24px;
    width: 1536px;
    margin: 20px auto;
}

.home_page .request_mint_access .section1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 10px;
}

.home_page .request_mint_access .section1 h2 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 140%;
    color: #FFFFFF;
    margin: 0 0 10px;
    text-align: left;
}

.home_page .request_mint_access .section1 p {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 140%;
    color: #FFFFFF;
    text-align: left;
}

.home_page .request_mint_access .section2 button {
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 33px;
    text-align: center;
    letter-spacing: 0.005em;
    background: linear-gradient(91.79deg, #fac01e 3.15%, #fc762a 85.66%);
    backdrop-filter: blur(5px);
    border-radius: 10px;
    text-transform: capitalize;
    padding: 20px;
    width: max-content;
}

.home_page .request_mint_access .section2 button p {
    background: linear-gradient(180deg, #0E151B 0%, #000000 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.home_page .lists {
    padding: 20px 0;
    background: none;
}

.home_page .list_page:nth-child(n + 11) {
    display: none;
}

.home_page .campaigns_page .card:nth-child(n + 9) {
    display: none;
}

.launchpad_list .list_details:nth-child(n + 17) {
    display: none;
}

/* header */

.home_page .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.home_page .header .explore_more button {
    background: rgba(33, 38, 43, .4);
    border-radius: 100px;
    padding: 5px 20px;
}

.home_page .header .explore_more button p {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    text-align: center;
    background: linear-gradient(91.79deg, #fac01e 3.15%, #fc762a 85.66%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: initial;
}

.home_page .header .explore_more button svg {
    margin-left: 10px;
}

.flix_drop_banner .explore_button {
    margin-left: 20px;
}

/*@media (min-width: 1536px) {*/
/*    .home_page .launchpad_page,*/
/*    .auctions_page,*/
/*    .home_page .collect_now_page {*/
/*        padding: 40px 0;*/
/*    }*/

/*    .home_page .request_mint_access {*/
/*        !*padding: 40px 0;*!*/
/*    }*/
/*}*/

@media (min-width: 1536px) {
    .home_page .flix_drop_banner {
        max-width: 1500px;
        margin: 40px auto auto;
        width: 1500px;
    }

    .home_page .launchpad_page {
        max-width: 1500px;
        margin: 20px auto;
        width: 1500px;
        padding: 20px 0;
    }

    .home_page .request_mint_access {
        max-width: 1500px;
        margin: 20px auto;
        width: 1500px;
    }

    .auctions_page {
        max-width: 1500px;
        margin: 20px auto;
        width: 1500px;
        padding: 40px 0;
    }
}

@media (max-width: 1536px) {
    .home_page .list_page:nth-child(n + 9) {
        display: none;
    }

    .home_page .campaigns_page .card:nth-child(n + 7) {
        display: none;
    }

    .launchpad_list .list_details:nth-child(n + 13) {
        display: none;
    }

    .home_page .flix_drop_banner {
        width: unset;
        margin: 20px;
    }

    .home_page .launchpad_page {
        width: 100%;
    }

    .home_page .request_mint_access {
        width: 100%;
    }

    .auctions_page,
    .collect_now_page {
        width: 100%;
    }

    .home_page .mint_manage_page {
        width: auto;
        margin: 5% 40px;
    }
}

@media (max-width: 1200px) {
    .home_page .list_page:nth-child(n + 7) {
        display: none;
    }

    .home_page .campaigns_page .card:nth-child(n + 5) {
        display: none;
    }

    .launchpad_list .list_details:nth-child(n + 9) {
        display: none;
    }
}

@media (max-width: 900px) {
    .home_page .list_page:nth-child(n + 5) {
        display: none;
    }

    .home_page .campaigns_page .card:nth-child(n + 3) {
        display: none;
    }

    .launchpad_list .list_details:nth-child(n + 5) {
        display: none;
    }
}

@media (max-width: 768px) {
    .home_page .request_mint_access {
        flex-direction: column;
        padding: 20px;
    }

    .home_page .request_mint_access .section2 button {
        padding: 10px 20px;
        margin-top: 30px;
    }

    .home_page .request_mint_access .section1 {
        align-items: center;
    }

    .home_page .request_mint_access .section1 p {
        text-align: center;
    }

    .home_page .request_mint_access .section1 h2 {
        font-size: 30px;
        line-height: 120%;
        text-align: center;
    }

    .home_page .request_mint_access .section1 p {
        font-size: 20px;
    }

    .home_page .flix_drop_banner {
        flex-direction: column;
        align-items: center;
    }
}

@media (max-width: 425px) {
    .home_page .flix_drop_banner .right {
        flex-direction: column;
    }

    .flix_drop_banner .explore_button {
        margin: 20px 0 0 0;
    }
}

body .skeleton_loader {
    background: #46464680;
    /*background: rgb(0 0 0 / 40%);*/
}

body .skeleton_loader:after {
    background: linear-gradient(90deg, transparent, #79797930, transparent);
    /*background: linear-gradient(90deg, transparent, rgb(0 0 0 / 80%), transparent);*/
}

.profile_skeleton .skeleton_loader {
    border-radius: 50px;
    transform: unset;
}

.collection_list .list_page.collection_skeleton_loader .row {
    flex-direction: column;
    align-items: flex-start;
}

.collection_table .collection_icon.skeleton_loader {
    border-radius: 0;
}

.ibc_tokens_table .skeleton_loader {
    margin: auto;
}

.ibc_tokens_table .token_value .skeleton_loader {
    margin: 0;
}

.token_value .collection_icon.skeleton_loader {
    width: 30px;
    height: 30px;
    margin-right: 15px;
    border-radius: 50px;
}

.auto_play .skeleton_loader {
    width: 100%;
    height: 100%;
    transform: unset;
    border-radius: 30px;
}

/* collection_header_skeleton */
.collections.collection_header_skeleton {
    border-color: #22272b;
    border-style: solid;
    border-width: 1px 0;
    padding: 30px 0;
    background: #191d21;
    max-width: 100%;
}

.marketplace_main_div .collections.collection_header_skeleton {
    background: #131618
}

.collection_header_skeleton {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 170px;
    background: #131618;
    border-width: 1px 0;
    border-style: solid;
    border-color: #22272B;
}

.collection_header_skeleton .name_section {

}

.collection_header_skeleton .name_section .skeleton_loader {
    border-radius: 10px;
}

.collection_header_skeleton .tabs_section {
    display: flex;
}

.collection_header_skeleton .tabs_section .tabs_section1 {
    margin-right: 10px;
}

/* SingleCampaignPageSkeletonLoaderRight */
.selected_nft .listing_data .list_section2 .p{
    height: 100%;
    width: 100%;
}
@media(max-width:1400px){
    .selected_nft{
        height: auto;
    }
}

/* SingleCampaignPageSkeletonLoaderLeft */
@media (max-width: 1600px) {
    .itc_single_left_section > div{
        width: 100%;
    }

    .itc_single_left_section > .section3 p{
        width: 100%;
    }
}
@media(max-width: 600px){
    .itc_single_left_section > .section1 .right p{
        width: 80%;
    }
    .itc_single_left_section > .section2 > div p{
        width: 10%;
    }
}

